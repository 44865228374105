import { ArrowBackIos, Build, Code, ContentCopy, PlayArrow } from '@mui/icons-material';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { blue, red } from '@mui/material/colors';
import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { myHistory } from '../../common/base/myHistory';
import { MyStringUtil } from '../../common/base/MyStringUtil';
import { CButton } from '../../common/components/CButton';
import { MsgBoxM } from '../../common/components/msgbox/MsgBoxM';
import { lang, langM } from '../../common/config/lang';
import { RootStateType } from '../../common/reducer/RootReducer';
import { MySystem } from '../../common/util/MySystem';
import { wconfig } from '../config/wconfig';
import { WMapInfoData, WRepository } from '../manager/WRepository';
import { WSystem } from '../manager/WSystem';
import { WPageReplyList } from './WPageReplyList';

export const WPagePlay = memo((props: { mapId: string; mode: string }) => {
   useSelector((state: RootStateType) => state.ui.forceUpdate);
   const [map, setMap] = useState<WMapInfoData | null>(null);
   const [mode, setMode] = useState<string>(props.mode);
   const [editable, setEditable] = useState(false);
   const [cloneable, setCloneable] = useState(false);
   const [close, setClose] = useState(false);
   const mapId = props.mapId;

   async function LoadMap() {
      let map = await WRepository.LoadMap(mapId);
      if (map) {
         setMap(map.data);
         if (WRepository.user) {
            setEditable(map.data.owner === WRepository.user.uid);
            setCloneable(map.data.owner === WRepository.user.uid);
         }
      }
   }

   useEffect(() => {
      LoadMap();
   }, [mapId]);

   if (!map) {
      return <Box />;
   } else {
      return (
         <Stack
            className={close ? 'toRight' : 'fromRight'}
            position={'fixed'}
            direction={'column'}
            width={'100%'}
            height={'100%'}
            alignItems={'center'}
            bgcolor="white"
            zIndex={1}
            overflow={'auto'}
         >
            <Box width={'100%'} zIndex={2}>
               <Stack
                  direction={'column'}
                  bgcolor={'black'}
                  width={'100%'}
                  height={wconfig.ui.게임최고높이}
                  maxHeight={'100vh'}
                  justifyContent={'start'}
                  alignItems={'center'}
               >
                  <iframe
                     id={mapId}
                     title={mapId}
                     frameBorder={0}
                     width={'100%'}
                     height={'100%'}
                     style={{ maxWidth: wconfig.ui.게임최고넓이 }}
                     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; microphone; camera; vr; speaker;"
                     allowFullScreen
                     src={`${MyStringUtil.GetHostURL()}/worldmaker/loader.html?mode=${mode}&window=embed&room=${mapId}&splash=${
                        map.splash ? encodeURIComponent(map.splash) : ''
                     }`}
                  ></iframe>
               </Stack>
            </Box>
            <Box width={'100%'} maxWidth={wconfig.ui.게임최고넓이}>
               <Stack p={0.5} direction={'row'} spacing={1} justifyContent={'end'}>
                  <IconButton
                     children={<ArrowBackIos />}
                     color={'primary'}
                     sx={{ fontSize: '0.9em' }}
                     onClick={() => {
                        setClose(true);
                        setTimeout(() => {
                           myHistory.push('/home');
                           MySystem.ForceUpdate();
                        }, 500);
                     }}
                  />
                  <Box flex={1}></Box>
                  <CButton
                     size="small"
                     sx={{ fontSize: '0.9em' }}
                     color={'secondary'}
                     startIcon={<Code />}
                     onClick={async () => {
                        let code =
                           `<iframe title="${map.title}"` +
                           ` frameBorder=0 width="100%" height="500" style="max-width:900px;"` +
                           ` allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; microphone; camera; vr; speaker;"` +
                           ` allowfullscreen` +
                           ` src="${MyStringUtil.GetHostURL()}/embed/${mapId}">` +
                           `</iframe>`;
                        navigator.clipboard.writeText(code);
                        MsgBoxM.Open(code, lang.common.복사완료);
                     }}
                  >
                     {lang.common.소스코드복사}
                  </CButton>
                  {cloneable && (
                     <CButton
                        size="small"
                        sx={{ fontSize: '0.9em' }}
                        startIcon={<ContentCopy />}
                        color={'secondary'}
                        onClick={async () => {
                           WSystem.OpenRoomCreateWindow(map, mapId);
                        }}
                     >
                        {lang.common.복제}
                     </CButton>
                  )}
                  {editable && mode === 'play' && (
                     <CButton
                        size="small"
                        startIcon={<Build />}
                        // sx={{ fontSize: '0.9em' }}
                        onClick={async () => {
                           let ret = await WSystem.OpenRoomInfoWindow(mapId, map, () => {
                              setMode('edit');
                           });
                           if (typeof ret === 'object') {
                              setMap(Object.assign(map, ret.btn));
                           }
                        }}
                     >
                        {lang.common.건설.toUpperCase()}
                     </CButton>
                  )}
                  {mode === 'edit' && (
                     <CButton
                        size="small"
                        startIcon={<PlayArrow />}
                        // sx={{ fontSize: '0.9em' }}
                        onClick={async () => {
                           // let run = false;
                           // if (WRepository.user) {
                           //    run = true;
                           // } else {
                           //    run = true;
                           //    if (config.USE_LOGIN) {
                           //       let ret = await MsgBoxM.Open(
                           //          lang.common.로그인하지않고계속진행하시겠습니까,
                           //          lang.common.로그인,
                           //          lang.common.계속,
                           //       );
                           //       if (ret.btn === lang.common.로그인) {
                           //          // 로그인
                           //          await MyFirebaseM.Login();
                           //          run = false;
                           //       } else if (ret.btn === 'Cancel') {
                           //          //비로그인 상태로 진행
                           //          run = true;
                           //       } else {
                           //          run = false;
                           //       }
                           //    }
                           // }
                           // if (run) {
                           // WSystem.OpenMap(mapId);
                           setMode('play');
                           // }
                        }}
                     >
                        {lang.common.플레이.toUpperCase()}
                     </CButton>
                  )}
               </Stack>
               <Box width={'100%'} p={0}>
                  <Box>
                     <Stack
                        ml={0.5}
                        mr={0.5}
                        direction={'row'}
                        justifyContent="flex-end"
                        alignItems={'center'}
                        spacing={0.5}
                        fontSize={'0.8em'}
                        color={'text.secondary'}
                     >
                        <Box flex={1} textAlign={'left'}>{`${lang.common.카테고리} > ${langM.GetLocalizedText(
                           'common',
                           map.category || 'all',
                        )}`}</Box>
                        <Box>
                           {lang.common.조회수} {map.view ?? 0}
                        </Box>
                        <Box pl={0.5} pr={0.5}>
                           •
                        </Box>
                        <Box>
                           {lang.common.생성일}{' '}
                           {map.createdAt.toDate().toLocaleDateString(langM.GetCurrentLocale())}
                        </Box>
                     </Stack>

                     <Stack
                        direction={'row'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        // borderRadius={'5px'}
                        border={'1px solid #eaeaea'}
                     >
                        <Box
                           sx={{
                              width: '90px',
                              height: '90px',
                              p: 0.5,
                           }}
                        >
                           {map.splash ? (
                              <img
                                 src={map.splash}
                                 alt={map.title}
                                 width={'100%'}
                                 style={{ borderRadius: 5 }}
                              />
                           ) : (
                              <Stack
                                 width={'100%'}
                                 height={'100%'}
                                 justifyContent={'center'}
                                 alignItems={'center'}
                                 bgcolor={'lightgray'}
                                 style={{ borderRadius: 5 }}
                              >
                                 {/* {lang.common.내용없음} */}
                              </Stack>
                           )}
                        </Box>
                        <Box
                           flex={1}
                           // ml={1}
                           p={0.7}
                           height={100}
                           borderRadius={'5px'}
                           // border={'1px solid #dadde9'}
                           overflow={'auto'}
                        >
                           <Typography
                              flex={1}
                              // marginRight={'auto'}// 나머지를 오른쪽으로 밀어버린다.
                              // noWrap
                              color={`primary`}
                              fontSize="1.1em"
                              fontWeight="bold"
                              textAlign="left"
                              textOverflow={'ellipsis'}
                           >
                              {map.title}
                           </Typography>

                           <Box>
                              {/* pre + wrap */}
                              <Typography
                                 fontSize="0.9em"
                                 textAlign={'left'}
                                 whiteSpace={'pre-wrap'}
                                 textOverflow={'ellipsis'}
                              >
                                 {map.desc}
                              </Typography>
                           </Box>
                        </Box>
                     </Stack>
                     <Stack
                        m={0.5}
                        mb={0}
                        height={'100%'}
                        direction={'row'}
                        justifyContent="center"
                        alignItems={'center'}
                        spacing={0.5}
                     >
                        {!map.sort && (
                           <Box p={0.5} pt={0} pb={0} borderRadius={5} bgcolor={blue[200]} fontSize={'0.6em'}>
                              {lang.common.내용없음}
                           </Box>
                        )}{' '}
                        {map.visibility === 'private' && (
                           <Box p={0.5} pt={0} pb={0} borderRadius={5} bgcolor={red[200]} fontSize={'0.6em'}>
                              {lang.common.비공개}
                           </Box>
                        )}
                        {map.visibility === 'friends' && (
                           <Box p={0.5} pt={0} pb={0} borderRadius={5} bgcolor={red[200]} fontSize={'0.6em'}>
                              {lang.common.친구만공개}
                           </Box>
                        )}
                        <Box flex={1} sx={{ bgcolor: 'red' }}></Box>
                        {/* <Box sx={{ transform: 'scale(0.7)', transformOrigin: 'right' }}>
                           <WAvatar
                              photo={map.ownerPhoto}
                              onClick={() => {
                                 map.owner && WSystem.OpenUserInfoWindowById(map.owner);
                              }}
                           >
                              <Typography fontWeight="bold" color={'text.secondary'}>
                                 {map.ownerName}
                              </Typography>
                           </WAvatar>
                        </Box> */}
                        {/* <Stack pt={1} direction={'row'} spacing={1}>
                           <Box p={0.5}>
                              <Typography>
                                 {lang.common.댓글} {map.replyCount || 0}
                              </Typography>
                           </Box>
                           <CButton size="small" variant={'outlined'} startIcon={<w />}>
                              <Typography>{map.like || 0}</Typography>
                           </CButton>
                           <CButton size="small" variant={'outlined'} startIcon={<ThumbDownOffAlt />}>
                              <Typography>{map.dislike || 0}</Typography>
                           </CButton>
                        </Stack> */}
                     </Stack>
                  </Box>
               </Box>
               <Box pl={1} pr={1}>
                  <WPageReplyList parentId={props.mapId} mapId={props.mapId} />
               </Box>
            </Box>
         </Stack>
      );
   }
});
