import { memo, useState } from 'react';

import { Box, IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material';

import { MoreVert, ThumbDownAlt, ThumbDownOffAlt, ThumbUpAlt, ThumbUpOffAlt } from '@mui/icons-material';
import React from 'react';
import { CButton } from '../../common/components/CButton';
import { ModalM } from '../../common/components/msgbox/ModalM';
import { MsgBoxM } from '../../common/components/msgbox/MsgBoxM';
import { ValidatedTextField } from '../../common/components/validate/ValidatedTextField';
import { lang, langM } from '../../common/config/lang';
import { MyFirebaseM } from '../../common/manager/MyFirebaseM';
import { WAvatar } from '../components/WAvatar';
import { wconfig } from '../config/wconfig';
import { WReplyData, WRepository } from '../manager/WRepository';
import { WSystem } from '../manager/WSystem';
import { WPageReplyList } from './WPageReplyList';

export const WReply = memo(
   (props: {
      reply: { uid: string; data: WReplyData };
      cbDelete?: () => void;
      propsPage: any;
      LoadReplyList: () => void;
   }) => {
      const rData = props.reply.data;
      const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
      const [editMode, setEditMode] = React.useState(false);
      const open = Boolean(anchorEl);
      const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
         setAnchorEl(event.currentTarget);
      };
      const isRootNode = props.propsPage.cbDelete ? false : true;
      const [inputT, setInputT] = useState('');
      const [inputTCopy, setInputTCopy] = useState('');
      const rLiked = WRepository.IsLiked(props.reply.uid);

      async function handleClickReply(requireLogin = false) {
         if (requireLogin && !WRepository.user) {
            let ret = await MsgBoxM.Open(
               lang.common.로그인이필요합니다,
               lang.common.로그인,
               lang.common.취소,
            );
            if (ret.btn === lang.common.로그인) {
               MyFirebaseM.Login();
            }
            return;
         }
         if (props.reply.data.parentId && props.reply.data.mapId)
            ModalM.Open(
               <Box width={wconfig.ui.게임최고넓이} maxWidth={'calc(100vw - 50px)'} pt={1.5}>
                  <WPageReplyList
                     parent={props.reply}
                     parentId={props.reply.uid}
                     mapId={props.reply.data.mapId}
                     cbCreate={() => {
                        props.LoadReplyList();
                     }}
                     cbDelete={() => {
                        props.LoadReplyList();
                     }}
                  />
               </Box>,
            );
      }

      return (
         <Box p={0.5} textAlign={'left'}>
            {/* <SMJsonPage data={reply.data} /> */}
            <Stack direction="row" spacing={1} alignItems={'start'}>
               {/* 아바타 */}
               <WAvatar
                  shortWidth
                  photo={rData.ownerPhoto}
                  onClick={() => {
                     rData.owner && WSystem.OpenUserInfoWindowById(rData.owner);
                  }}
               ></WAvatar>
               {/* 내용 */}
               <Box flex={1}>
                  <Box color={'text.secondary'} fontSize={'0.8em'}>
                     {rData.ownerName} • {rData.createdAt.toDate().toLocaleString(langM.GetCurrentLocale())}
                  </Box>
                  {rData.deleted ? (
                     <Typography whiteSpace={'pre-wrap'} color="text.secondary">
                        {lang.common.삭제된댓글}
                     </Typography>
                  ) : editMode ? (
                     <>
                        <ValidatedTextField
                           multiline
                           fullWidth
                           sx={{ flex: 1, mt: 1, mb: 1 }}
                           value={inputT}
                           onChange={(evt: any) => {
                              setInputT(evt.target.value);
                           }}
                        />
                        <Stack pt={0} direction="row" alignItems="center" spacing={1} justifyContent="end">
                           <CButton
                              size={'small'}
                              onClick={async () => {
                                 setEditMode(false);
                              }}
                           >
                              {lang.common.취소}
                           </CButton>
                           <CButton
                              size={'small'}
                              disabled={inputT.trim().length === 0 || inputT.trim() === inputTCopy.trim()}
                              onClick={async () => {
                                 let replyData: WReplyData = {
                                    text: inputT.trim(),
                                 };
                                 if (await WRepository.SaveReply(props.reply.uid, replyData)) {
                                    // LoadReplyList();
                                    rData.text = inputT.trim();
                                    setEditMode(false);
                                 }
                              }}
                           >
                              {lang.common.확인}
                           </CButton>
                        </Stack>
                     </>
                  ) : (
                     <Typography whiteSpace={'pre-wrap'}>{rData.text}</Typography>
                  )}
                  {/* {isRootNodeReply && ( */}
                  <Stack direction={'row'} spacing={0} alignItems="center">
                     {isRootNode && (
                        <Box pr={2}>
                           <CButton
                              size="small"
                              // variant={'outlined'}
                              // sx={{ p: 0 }}
                              onClick={() => handleClickReply(!rData.replyCount)}
                           >
                              {lang.common.댓글} {rData.replyCount ? rData.replyCount : ''}
                           </CButton>
                        </Box>
                     )}
                     <CButton
                        size="small"
                        variant={'text'}
                        startIcon={rLiked === true ? <ThumbUpAlt /> : <ThumbUpOffAlt />}
                        onClick={async () => {
                           WRepository.UpdateLike(
                              props.reply.uid,
                              true,
                              rLiked === true,
                              rLiked === false,
                              rData,
                              'reply',
                           );
                        }}
                     >
                        {rData.like || 0}
                     </CButton>
                     <CButton
                        size="small"
                        variant={'text'}
                        startIcon={rLiked === false ? <ThumbDownAlt /> : <ThumbDownOffAlt />}
                        onClick={async () => {
                           WRepository.UpdateLike(
                              props.reply.uid,
                              false,
                              rLiked === true,
                              rLiked === false,
                              rData,
                              'reply',
                           );
                        }}
                     >
                        {rData.dislike || 0}
                     </CButton>
                  </Stack>
                  {/* )} */}
               </Box>
               {WRepository.user && (
                  <>
                     {/* 메뉴 */}
                     <IconButton onClick={handleClick}>
                        <MoreVert />
                     </IconButton>

                     <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={() => {
                           setAnchorEl(null);
                        }}
                        MenuListProps={{
                           'aria-labelledby': 'basic-button',
                        }}
                     >
                        {rData.owner === WRepository.user.uid && (
                           <MenuItem
                              onClick={async () => {
                                 setAnchorEl(null);
                                 let ret = await MsgBoxM.Open(
                                    lang.common.정말삭제하시겠습니까,
                                    lang.common.확인,
                                    lang.common.취소,
                                 );
                                 if (ret.btn === lang.common.확인) {
                                    if (props.reply.data.replyCount) {
                                       // MsgBoxM.Open(lang.common.댓글이있어삭제할수없습니다);
                                       if (
                                          await WRepository.SaveReply(props.reply.uid, {
                                             deleted: 1,
                                          })
                                       ) {
                                          //부모 기준에서 댓글 개수 감소
                                          if (props.propsPage.parent?.data.replyCount) {
                                             --props.propsPage.parent.data.replyCount;
                                          }
                                          props.cbDelete?.();
                                          props.propsPage.cbDelete?.();
                                          props.LoadReplyList();
                                       }
                                    } else if (await WRepository.DeleteReply(props.reply.uid, rData)) {
                                       // setReplyList(replyList.filter((r) => r.uid !== p.reply.uid));
                                       //부모 기준에서 댓글 개수 감소
                                       if (props.propsPage.parent?.data.replyCount) {
                                          --props.propsPage.parent.data.replyCount;
                                       }
                                       props.cbDelete?.();
                                       props.propsPage.cbDelete?.();
                                       props.LoadReplyList();
                                    }
                                 }
                              }}
                           >
                              {lang.common.삭제}
                           </MenuItem>
                        )}
                        {rData.owner === WRepository.user.uid && (
                           <MenuItem
                              onClick={async () => {
                                 setAnchorEl(null);
                                 setEditMode(true);
                                 rData.text && setInputT(rData.text);
                                 rData.text && setInputTCopy(rData.text);
                              }}
                           >
                              {lang.common.편집}
                           </MenuItem>
                        )}
                        {/* <MenuItem
                  onClick={() => {
                     setAnchorEl(null);
                  }}
               >
                  {lang.common.편집}
               </MenuItem> */}
                     </Menu>
                  </>
               )}
            </Stack>
         </Box>
      );
   },
);
