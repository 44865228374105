import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { MyStringUtil } from '../../common/base/MyStringUtil';
import { RootStateType } from '../../common/reducer/RootReducer';
import { WRepository } from '../manager/WRepository';
import { WPageLoading } from './WPageLoading';

export const WPageEmbed = (props: { page: string; currentRoomID: string; mode: string }) => {
   useSelector((state: RootStateType) => state.ui.forceUpdate);
   const [splash, setSplash] = useState(null);

   useEffect(() => {
      console.log('WPageEmbed: ', props.currentRoomID);
      WRepository.LoadMap(props.currentRoomID).then((data) => {
         setSplash(data?.data.splash);
      });
   }, [props.currentRoomID, props.page, props.page]);

   if (!splash) {
      return <WPageLoading />;
   } else {
      return (
         <Box>
            {splash !== null && (
               <iframe
                  id={props.currentRoomID}
                  title={props.currentRoomID}
                  frameBorder={0}
                  width={'100%'}
                  height={window.innerHeight}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; microphone; camera; vr; speaker;"
                  allowFullScreen
                  src={`${MyStringUtil.GetHostURL()}/worldmaker/loader.html?mode=${
                     props.mode
                  }&window=embed&room=${props.currentRoomID}&splash=${
                     splash ? encodeURIComponent(splash) : ''
                  }`}
               ></iframe>
            )}
         </Box>
      );
   }
};
