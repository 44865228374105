let fileSelectorDiv: HTMLElement | undefined = undefined;

export class MyBrowserUtil {
   static SaveBlobToFile(blob: Blob, fileName: string) {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
   }
   // Content wrapper element
   // Button callback
   static async SelectFiles() {
      if (!fileSelectorDiv) {
         fileSelectorDiv = document.createElement('elee-file-selector');
         fileSelectorDiv.style.position = 'absolute';
         fileSelectorDiv.style.width = '400px';
         fileSelectorDiv.style.height = '400px';
      }
      let files = await this.SelectFile('image/*', true);
      fileSelectorDiv.innerHTML = (files as any[])
         .map((file) => `<img src="${URL.createObjectURL(file)}" style="width: 100px; height: 100px;">`)
         .join('');
   }

   // ---- function definition ----
   /**  */
   static async SelectFile(contentType = 'image/*', multiple = false) {
      return new Promise((resolve) => {
         let input = document.createElement('input');
         input.type = 'file';
         input.multiple = multiple;
         input.accept = contentType;
         input.onchange = (_) => {
            let files: File[] = Array.from((input as any).files);
            if (multiple) resolve(files);
            else resolve(files[0]);
         };
         input.click();
      });
   }
}
