import { reduxStore } from './reduxStore';

/**
 * @author elee
 * @date 2021-03-29,
 */
const SET_CATEGORY = 'SET_CATEGORY';
const LOGS = 'STATUS_BAR_LOG';
const LOGS_CLEAR = 'STATUS_BAR_LOG_CLEAR';
const LOGS_PATCH = 'STATUS_BAR_PATCH';
const FORCE_UPDATE = 'FORCE_UPDATE';
const SET_TASK_COUNT = 'SET_TASK_COUNT';
const SIDE_MENU_OPEN = 'SIDE_MENU_OPEN';

export namespace UIAction {
   /** 메인 메뉴 슬라이드 열기 닫기 */
   export function SetSideMenuOpen(open: boolean) {
      reduxStore.dispatch({ type: SIDE_MENU_OPEN, payload: open });
   }

   /** 로그 추가 */
   export function AddLog(pram: string) {
      reduxStore.dispatch({ type: LOGS, payload: pram });
   }

   /** 로그 추가 */
   export function ClearLog() {
      reduxStore.dispatch({ type: LOGS_CLEAR, payload: '' });
   }

   /** 로그 수정 */
   export function PatchLog(pram: string) {
      reduxStore.dispatch({ type: LOGS_PATCH, payload: pram });
   }

   /** 강제 업데이트 */
   export function ForceUpdate() {
      reduxStore.dispatch({ type: FORCE_UPDATE, payload: '' });
   }

   /** 태스크 */
   export function SetTaskCount(pram: number) {
      reduxStore.dispatch({ type: SET_TASK_COUNT, payload: pram });
   }

   /** 태스크 */
   export function SetCategory(pram: string) {
      reduxStore.dispatch({ type: SET_CATEGORY, payload: pram });
   }
}

/** 스토어 구조 */
interface IStore {
   sideMenuOpen: boolean;
   forceUpdate: boolean;
   statusBarLogs: string[]; //로그
   statusBarStatus: string; //우하단 정보
   taskCount: number;
   category: string;
}

// !초기 스토어 상태
const initialState: IStore = {
   sideMenuOpen: false,
   forceUpdate: false,
   statusBarLogs: [],
   statusBarStatus: '',
   taskCount: 0,
   category: '',
};

/** 리듀서 */
export function UIReducer(state: IStore = initialState, action: { type: any; payload: any }): IStore {
   switch (action.type) {
      case SIDE_MENU_OPEN:
         return {
            ...state,
            sideMenuOpen: action.payload,
         };
      case SET_CATEGORY:
         return {
            ...state,
            category: action.payload, //마지막에 로그 추가
         };
      case LOGS:
         return {
            ...state,
            statusBarLogs: [...state.statusBarLogs, action.payload], //마지막에 로그 추가
         };
      case LOGS_CLEAR:
         return {
            ...state,
            statusBarLogs: [],
         };
      case LOGS_PATCH: {
         // '1: xxx' 에서 1 을 추출해서 1번 로그의 내용을 xxx로 교체한다
         const log = String(action.payload);
         const idx = log.indexOf(':');
         if (idx > 0) {
            const key = log.substring(0, idx);
            const list = [...state.statusBarLogs]; //리스트 복제본 생성
            //뒤에서 부터 찾아서 교체한다.
            for (let i = list.length - 1; i >= 0; --i) {
               if (list[i].startsWith(key)) {
                  list[i] = log;
                  break;
               }
            }
            return {
               ...state,
               statusBarLogs: list,
            };
         }
         break;
         // return {
         //   ...state,
         //   statusBarLogs: [...state.statusBarLogs, action.payload], //마지막에 로그 추가
         // };
      }
      case FORCE_UPDATE:
         return {
            ...state,
            forceUpdate: !state.forceUpdate,
         };
      case SET_TASK_COUNT:
         return {
            ...state,
            taskCount: action.payload,
         };
   }
   return state;
}
