import './WMain.css';

import { FirebaseOptions } from 'firebase/app';
import { MetaMaskProvider } from 'metamask-react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';

import { Box, CssBaseline, ThemeProvider } from '@mui/material';

import { APIBase } from '../../common/api/APIBase';
import { myHistory } from '../../common/base/myHistory';
import { MyStringUtil } from '../../common/base/MyStringUtil';
import { CConsole } from '../../common/components/CConsole';
import { ModalM } from '../../common/components/msgbox/ModalM';
import { MsgBox } from '../../common/components/msgbox/MsgBox';
import { MsgBoxM } from '../../common/components/msgbox/MsgBoxM';
import { CNotificationStack } from '../../common/components/toast/CNotificationStack';
import { config } from '../../common/config/config';
import { lang, langM } from '../../common/config/lang';
import { MyFirebaseM } from '../../common/manager/MyFirebaseM';
import { RootStateType } from '../../common/reducer/RootReducer';
import { MyDebug } from '../../common/util/MyDebug';
import { MySystem } from '../../common/util/MySystem';
import { WAccount } from '../manager/WAccount';
import { WRepository } from '../manager/WRepository';
import { WSystem } from '../manager/WSystem';
import { w_theme } from '../theme/w_theme';
import { WPageAbout } from './WPageAbout';
import { WPageEmbed } from './WPageEmbed';
import { WPageLoading } from './WPageLoading';
import { WPageMain } from './WPageMain';
import { WPagePlay } from './WPagePlay';
import { WPageChannel } from './WPageChannel';

// let showAboutDefault = true;

export const WMain = (props: { page?: string; contentId?: string; mode?: string }) => {
   // useSelector((state: RootStateType) => state.ui.forceUpdate);
   const [initialized, setInitialized] = useState(false);

   /// 초기화
   useEffect(() => {
      init().then(() => {
         setInitialized(true);
         // if (!WRepository.user && !props.page) {
         //    // 로그인 하지 않은 상태에서 루트페이지 조회시 어바웃 페이지로 이동
         //    myHistory.push('/about');
         //    MySystem.ForceUpdate();
         // }
      });
      // window.addEventListener('resize', HandleResize);
      // window.addEventListener('keydown', HandleKeydown);
      window.addEventListener('message', HandlePostMessage);
      // return () => {
      //    window.removeEventListener('resize', HandleResize);
      //    window.removeEventListener('keydown', HandleKeydown);
         window.removeEventListener('message', HandlePostMessage);
      // };
   }, []);

   /// 렌더링
   function RenderMain() {
      // 초기화 되지 않았으면 로딩
      if (!initialized) return <WPageLoading />;

      // 페이지
      if (props.contentId && props.page === 'embed') {
         // 방으로 직접 접속
         return <WPageEmbed page={props.page} currentRoomID={props.contentId} mode={props.mode || 'play'} />;
      } else {
         // 메인 페이지
         return (
            <Router history={myHistory}>
               <Switch>
                  {/* 메인 */}
                  <Route exact path="/home">
                     <WPageMain />
                  </Route>
                  {/* 플레이 */}
                  <Route path="/play">
                     {props.page === 'play' && props.contentId && (
                        <WPagePlay
                           // page={props.page}
                           mapId={props.contentId}
                           mode={props.mode || 'play'}
                        />
                     )}
                  </Route>
                  {/* 채널 */}
                  <Route path="/channel">
                     {props.page === 'channel' && props.contentId && (
                        <WPageChannel
                           // page={props.page}
                           userId={props.contentId}
                           mode={props.mode}
                        />
                     )}
                  </Route>
                  {/* 어바웃 */}
                  <Route path="/about">
                     <WPageAbout />
                  </Route>
               </Switch>
            </Router>
         );
      }
   }

   return (
      <MetaMaskProvider>
         <ThemeProvider theme={w_theme}>
            <CssBaseline />
            <MsgBox
               data={useSelector((state: RootStateType) => state.modal)}
               onClose={() => ModalM.CloseWithoutCallback()}
            />
            <MsgBox
               data={useSelector((state: RootStateType) => state.msg)}
               onClose={() => MsgBoxM.CloseWithoutCallback()}
            />
            <CNotificationStack />
            <Box
               className={ModalM.IsOpen() || MsgBoxM.IsOpen() ? 'blur' : undefined}
               bgcolor={'black'}
               width="100vw"
               height={window.innerHeight}
            >
               {RenderMain()}
            </Box>
            <CConsole showTasks />
         </ThemeProvider>
      </MetaMaskProvider>
   );
};

async function init() {
   // 초기화
   MyDebug.LogClear();
   MyDebug.Log(`${config.TITLE}`);
   MyDebug.Log(MyStringUtil.FormatDateTime(new Date()));
   MyDebug.Log('User Agent: ' + navigator.userAgent);

   // 제목 변경
   document.title = lang.w.제목;
   // description 변경
   document.querySelector('meta[name="description"]')?.setAttribute('content', lang.w.어바웃01);

   // API 초기화
   APIBase.Init(WAccount.s, config.URL_API_SERVER);

   // 파이어베이스 초기화
   const firebaseConfig: FirebaseOptions = {
      apiKey: 'AIzaSyDB0uaz8zpZMEhaWNYByRCeI2Loo6fja3k',
      authDomain: 'my-world-maker.firebaseapp.com',
      projectId: 'my-world-maker',
      storageBucket: 'my-world-maker.appspot.com',
      messagingSenderId: '150714713192',
      appId: '1:150714713192:web:73446593fa8211a5e7a7dd',
      measurementId: 'G-894QKHBVPK',
   };
   await MyFirebaseM.Init(firebaseConfig);

   // 게임 초기화
   await WRepository.Init();

   MySystem.Init();
}

async function HandlePostMessage(event: MessageEvent) {
   function _PostMessageCallback(evt: MessageEvent, data: any) {
      evt.source?.postMessage({ callbackId: evt.data.callbackId, data: data }, { targetOrigin: evt.origin });
   }

   if (event.data?.type) {
      let type = event.data.type;
      let data = event.data.data;

      let result: any = 'ok';
      switch (type) {
         case 'Init': {
            InitFullScreen();
            break;
         }
         case 'ForceUpdate': {
            MySystem.ForceUpdate();
            break;
         }
         case 'SelectLanguage': {
            langM.SetCurrentLanguage(langM.GetCurrentLanguage());
            MySystem.ForceUpdate();
            break;
         }
         case 'FullScreen': {
            ToggleFullScreen(data.iframeId);
            break;
         }
         case 'MsgBox': {
            result = await MsgBoxM.OpenEx(data.msg, data.btns, data.inputs, true);
            break;
         }
         case 'UserInfo': {
            let uid = data.uid;
            if (uid) {
               await WSystem.OpenUserInfoWindowById(uid);
               if (uid === WRepository.user?.uid) result = WRepository.user;
            }
            break;
         }
         case 'ImageViewer': {
            await WSystem.OpenImageViewer(data);
            break;
         }
         case 'ImageSelect': {
            break;
         }
      }
      result && _PostMessageCallback(event, result);
   }
}

function InitFullScreen() {
   let win: any = window;
   win.myIframesRollback = undefined;
   win.myFullScreenMode = false;
}

function ToggleFullScreen(iframeId: string) {
   if (!document.body.requestFullscreen) {
      if (MySystem.IsMobile()) return;
   } //풀스크린 지원 안함

   let win: any = window;
   let targetWin = window; //win.parent;
   try {
      let myIframe = targetWin.document.getElementById(iframeId);
      if (myIframe) {
         if (targetWin.document.fullscreenElement) {
            console.log('풀스크린:종료요청');
            targetWin.document.exitFullscreen();
         } else {
            //리스너 등록
            if (!win.myFullscreenChangeEventListener) {
               win.myFullscreenChangeEventListener = true;
               targetWin.document.addEventListener('fullscreenchange', function () {
                  // iframe 복원
                  if (win.myIframesRollback && !targetWin.document.fullscreenElement) {
                     console.log('풀스크린:원래창으로복원');
                     let iframes = win.myIframesRollback;
                     for (let i = 0; i < win.myIframesRollback.length; i++) {
                        // Object.assign(
                        //    win.myIframesRollback[i].style,
                        //    win.myIframesRollback[i].rollbackStyle,
                        // );
                        // win.myIframesRollback[i].style = win.myIframesRollback[i].rollbackStyle;
                        iframes[i].style.position = iframes[i].rollbackStyle.position;
                        iframes[i].style.top = iframes[i].rollbackStyle.top;
                        iframes[i].style.left = iframes[i].rollbackStyle.left;
                        iframes[i].style.width = iframes[i].rollbackStyle.width;
                        iframes[i].style.maxWidth = iframes[i].rollbackStyle.maxWidth;
                        iframes[i].style.height = iframes[i].rollbackStyle.height;
                        iframes[i].style.maxHeight = iframes[i].rollbackStyle.maxHeight;
                        iframes[i].style.zIndex = iframes[i].rollbackStyle.zIndex;
                        iframes[i].style.visibility = iframes[i].rollbackStyle.visibility;
                        iframes[i].style.display = iframes[i].rollbackStyle.display;
                     }
                     win.myIframesRollback = null;
                     win.myFullScreenMode = null;
                  }
               });
            }
            //풀스크린 실행
            if (!win.myFullScreenMode) {
               // 복원할 iframe 리스트 저장
               let iframes = targetWin.document.getElementsByTagName('iframe');
               if (iframes) {
                  for (let i = 0; i < iframes.length; i++) {
                     let frame: any = iframes[i];
                     frame.rollbackStyle = {};
                     frame.rollbackStyle.position = iframes[i].style.position;
                     frame.rollbackStyle.top = iframes[i].style.top;
                     frame.rollbackStyle.left = iframes[i].style.left;
                     frame.rollbackStyle.width = iframes[i].style.width;
                     frame.rollbackStyle.maxWidth = iframes[i].style.maxWidth;
                     frame.rollbackStyle.height = iframes[i].style.height;
                     frame.rollbackStyle.maxHeight = iframes[i].style.maxHeight;
                     frame.rollbackStyle.zIndex = iframes[i].style.zIndex;
                     frame.rollbackStyle.visibility = iframes[i].style.visibility;
                     frame.rollbackStyle.display = iframes[i].style.display;
                     if (iframes[i] !== myIframe) {
                        //모든 iframe 숨기기
                        if (iframes[i].style.visibility !== 'hidden') {
                           iframes[i].style.visibility = 'hidden';
                        }
                        if (iframes[i].style.display !== 'none') {
                           iframes[i].style.display = 'none';
                        }
                     }
                  }

                  // 아래에서 하면 안됨 (myIframe의 속성이 변해서 측정이 안됨))
                  let isFitScreen = targetWin.innerHeight === myIframe.offsetHeight || MySystem.IsMobile();

                  win.myIframesRollback = iframes;
                  myIframe.style.position = 'fixed';
                  myIframe.style.top = '0px';
                  myIframe.style.left = '0px';
                  myIframe.style.width = '100vw';
                  myIframe.style.maxWidth = '100vw';
                  myIframe.style.height = `100vh`;
                  myIframe.style.maxHeight = '100vh';
                  //100vh나 window.innerHeight 둘다 안됨
                  //매번 window.addEventListener("resize", handleResize); 에서 window.innerHeight를 갱신해줄수도 없고
                  myIframe.style.zIndex = '1';

                  if (isFitScreen) {
                     win.myFullScreenMode = 2;
                     targetWin.document.body.requestFullscreen();
                     console.log('풀스크린:최종');
                  } else {
                     win.myFullScreenMode = 1;
                     console.log('풀스크린:중간');
                  }
               }
            } else if (win.myFullScreenMode === 1) {
               if (!document.body.requestFullscreen) {
                  console.log('풀스크린:원래창으로복원');
                  let iframes = win.myIframesRollback;
                  for (let i = 0; i < win.myIframesRollback.length; i++) {
                     // Object.assign(
                     //    win.myIframesRollback[i].style,
                     //    win.myIframesRollback[i].rollbackStyle,
                     // );
                     // win.myIframesRollback[i].style = win.myIframesRollback[i].rollbackStyle;
                     iframes[i].style.position = iframes[i].rollbackStyle.position;
                     iframes[i].style.top = iframes[i].rollbackStyle.top;
                     iframes[i].style.left = iframes[i].rollbackStyle.left;
                     iframes[i].style.width = iframes[i].rollbackStyle.width;
                     iframes[i].style.maxWidth = iframes[i].rollbackStyle.maxWidth;
                     iframes[i].style.height = iframes[i].rollbackStyle.height;
                     iframes[i].style.maxHeight = iframes[i].rollbackStyle.maxHeight;
                     iframes[i].style.zIndex = iframes[i].rollbackStyle.zIndex;
                     iframes[i].style.visibility = iframes[i].rollbackStyle.visibility;
                     iframes[i].style.display = iframes[i].rollbackStyle.display;
                  }
                  win.myIframesRollback = null;
                  win.myFullScreenMode = null;
               } else {
                  myIframe.style.height = `100vh`;
                  win.myFullScreenMode = 2;
                  targetWin.document.body.requestFullscreen();
                  console.log('풀스크린:최종');
               }
            }
         }
         return;
      }
   } catch (e) {}
}
