import { ThumbUpAlt } from '@mui/icons-material';
import { Box, BoxProps, Stack, Typography } from '@mui/material';
import { blue, red } from '@mui/material/colors';
import { useSelector } from 'react-redux';
import { myHistory } from '../../common/base/myHistory';
import { lang, langM } from '../../common/config/lang';
import { RootStateType } from '../../common/reducer/RootReducer';
import { MySystem } from '../../common/util/MySystem';
import { WMapInfoData } from '../manager/WRepository';
import { WSystem } from '../manager/WSystem';
import { WAvatar } from './WAvatar';

interface WArticleProps extends BoxProps {
   mapId: string;
   map: WMapInfoData;
}

export const WArticle = (props: WArticleProps) => {
   useSelector((state: RootStateType) => state.ui.forceUpdate);

   return (
      <Box
         className="zoomIn"
         // width="100%"
         p={1}
         sx={{
            borderRadius: 0.5,
            boxShadow: '0px 1px 2px 1px rgba(0, 0, 0, 0.3)',
         }}
         width={300}
         // maxWidth={360}
      >
         <Box>
            {/* 윗상태바 */}
            <Stack
               m={0.5}
               direction={'row'}
               justifyContent="flex-end"
               alignItems={'center'}
               // spacing={1.5}
               color={'text.secondary'}
               fontSize={'0.8em'}
            >
               <Box flex={1} textAlign={'left'}>{`> ${langM.GetLocalizedText(
                  'common',
                  props.map.category || 'all',
               )}`}</Box>
               <Box pl={1} pr={1}></Box>
               <Stack direction="row" alignItems={'center'} spacing={0.3}>
                  <ThumbUpAlt sx={{ fontSize: '1.2em' }} />
                  <Box>{props.map.like ?? 0}</Box>
               </Stack>
               <Box pl={0.5} pr={0.5}>
                  •
               </Box>
               <Box>
                  {lang.common.조회수} {props.map.view ?? 0}
               </Box>
               <Box pl={0.5} pr={0.5}>
                  •
               </Box>
               <Box textAlign={'right'}>
                  {/* {lang.common.생성일}{' '} */}
                  {props.map.createdAt.toDate().toLocaleDateString(langM.GetCurrentLocale())}
               </Box>
            </Stack>

            {/* 본문    */}
            <Stack
               direction={'row'}
               justifyContent={'center'}
               alignItems={'center'}
               borderRadius={'5px'}
               border={'1px solid #eaeaea'}
            >
               <Box
                  className={'button'}
                  sx={{
                     width: '90px',
                     height: '90px',
                     p: 0.5,
                  }}
                  onClick={() => {
                     myHistory.push(`/play/${props.mapId}`);
                     MySystem.ForceUpdate();
                  }}
               >
                  {props.map.splash ? (
                     <img
                        src={props.map.splash}
                        alt={props.map.title}
                        width={'100%'}
                        style={{ borderRadius: 5 }}
                     />
                  ) : (
                     <Stack
                        width={'100%'}
                        height={'100%'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        bgcolor={'lightgray'}
                        style={{ borderRadius: 5 }}
                     >
                        {/* {lang.common.내용없음} */}
                     </Stack>
                  )}
               </Box>
               <Box
                  flex={1}
                  // ml={1}
                  p={0.7}
                  height={100}
                  borderRadius={'5px'}
                  // border={'1px solid #dadde9'}
                  overflow={'auto'}
               >
                  {/* <Stack width={'100%'} direction={'column'} justifyContent="center"> */}
                  {/* <Stack pl={1} pr={0.5} direction={'column'} justifyContent="center"> */}
                  <Typography
                     flex={1}
                     // marginRight={'auto'}// 나머지를 오른쪽으로 밀어버린다.
                     // noWrap
                     color={`primary`}
                     fontSize="1.1em"
                     fontWeight="bold"
                     textAlign="left"
                     textOverflow={'ellipsis'}
                     className={'button'}
                     onClick={() => {
                        myHistory.push(`/play/${props.mapId}`);
                        MySystem.ForceUpdate();
                     }}
                  >
                     {props.map.title}
                  </Typography>

                  <Box>
                     {/* pre + wrap */}
                     <Typography
                        fontSize="0.9em"
                        textAlign={'left'}
                        whiteSpace={'pre-wrap'}
                        textOverflow={'ellipsis'}
                     >
                        {props.map.desc}
                     </Typography>
                     {/* {preview && (
                     <CPlayWindow sx={{ height: 500, width: '100%', zIndex: 1 }}>
                        <iframe
                           id={props.mapId}
                           title={props.map.title}
                           frameBorder={0}
                           width="100%"
                           height="100%"
                           style={{ borderRadius: 5 }}
                           allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; microphone; camera; vr; speaker;"
                           allowFullScreen
                           src={
                              wconfig.env.dev
                                 ? 'http://localhost:7456/'
                                 : `${MyStringUtil.GetHostURL()}/worldmaker/loader.html?mode=${mode}&window=embed&room=${
                                      props.mapId
                                   }&splash=${props.map.splash ? encodeURIComponent(props.map.splash) : ''}`
                           }
                        ></iframe>
                     </CPlayWindow>
                  )} */}
                  </Box>
               </Box>
            </Stack>
            <Stack
               m={0.5}
               mb={0}
               height={20}
               direction={'row'}
               justifyContent="center"
               alignItems={'center'}
               spacing={0.5}
            >
               {!props.map.sort && (
                  <Box p={0.5} pt={0} pb={0} borderRadius={5} bgcolor={blue[200]} fontSize={'0.6em'}>
                     {lang.common.내용없음}
                  </Box>
               )}{' '}
               {props.map.visibility === 'private' && (
                  <Box p={0.5} pt={0} pb={0} borderRadius={5} bgcolor={red[200]} fontSize={'0.6em'}>
                     {lang.common.비공개}
                  </Box>
               )}
               {props.map.visibility === 'friends' && (
                  <Box p={0.5} pt={0} pb={0} borderRadius={5} bgcolor={red[200]} fontSize={'0.6em'}>
                     {lang.common.친구만공개}
                  </Box>
               )}
               <Box flex={1} sx={{ bgcolor: 'red' }}></Box>
               <Box sx={{ transform: 'scale(0.7)', transformOrigin: 'right' }}>
                  {/* <Box> */}
                  <WAvatar
                     photo={props.map.ownerPhoto}
                     onClick={() => {
                        props.map.owner && WSystem.OpenUserInfoWindowById(props.map.owner);
                     }}
                  >
                     <Typography fontWeight="bold" color={'text.secondary'}>
                        {props.map.ownerName}
                     </Typography>
                  </WAvatar>
                  {/* </Box> */}
               </Box>
            </Stack>
         </Box>
      </Box>
   );
};
