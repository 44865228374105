import { Add, Build, Delete, PlayArrow } from '@mui/icons-material';
import { Box, FormLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { myHistory } from '../../common/base/myHistory';
import { MyStringUtil } from '../../common/base/MyStringUtil';
import { MyUtil } from '../../common/base/MyUtil';
import { CButton } from '../../common/components/CButton';
import { WPageImageEdit } from '../../common/components/image-crop/WPageImageEdit';
import { ModalM } from '../../common/components/msgbox/ModalM';
import { MsgBoxM } from '../../common/components/msgbox/MsgBoxM';
import { ToastM } from '../../common/components/toast/ToastM';
import { ValidatedSelect } from '../../common/components/validate/ValidatedSelect';
import { ValidatedTextField } from '../../common/components/validate/ValidatedTextField';
import { lang } from '../../common/config/lang';
import { MyFirebaseM } from '../../common/manager/MyFirebaseM';
import { RootStateType } from '../../common/reducer/RootReducer';
import { UIAction } from '../../common/reducer/UIReducer';
import { MyDebug } from '../../common/util/MyDebug';
import { MySystem } from '../../common/util/MySystem';
import { WSubTitle } from '../components/WTitle';
import { wconfig } from '../config/wconfig';
import { WMapInfoData, WRepository } from '../manager/WRepository';

// let inputValuesCheck: any = {};
let inputValues = resetValues();
function resetValues() {
   return {
      splash: {
         value: '',
         error: false,
         value0: '',
      },
      title: {
         value: '',
         error: false,
         value0: '',
      },
      desc: {
         value: '',
         error: false,
         value0: '',
      },
      map: {
         value: 'mapBlock001',
         error: false,
         value0: '',
      },
      mapSize: {
         value: 100,
         error: false,
         value0: 0,
      },
      category: {
         value: 'etc',
         error: false,
         value0: '',
      },
      visibility: {
         value: 'private',
         error: false,
         value0: '',
      },
   };
}

export const WPageCreateRoom = memo(
   (props: {
      currentMapId?: string;
      mapData?: WMapInfoData;
      copyTargetMapId?: string;
      newCategory?: string;
      cbEditWorld?: () => void;
   }) => {
      useSelector((state: RootStateType) => state.ui.forceUpdate);
      const [refresh, setRefresh] = useState(false);
      const [showError, setShowError] = useState(false);
      const clone = !props.currentMapId && props.mapData && props.copyTargetMapId;
      const [imageDataURL, setImageDataURL] = useState('');
      const isDataChanged = !!GetSaveData() || !!imageDataURL;

      useEffect(() => {
         inputValues = resetValues();
         if (props.mapData) {
            //수정 또는 복제 생성
            inputValues.splash.value0 = inputValues.splash.value = props.mapData.splash ?? '';
            inputValues.title.value0 = inputValues.title.value = props.mapData.title ?? '';
            inputValues.desc.value0 = inputValues.desc.value = props.mapData.desc ?? '';
            if (props.mapData.map) inputValues.map.value0 = inputValues.map.value = props.mapData.map;
            if (props.mapData.mapSize) inputValues.mapSize.value0 = inputValues.mapSize.value = props.mapData.mapSize;
            if (props.mapData.category)
               inputValues.category.value0 = inputValues.category.value = props.mapData.category;
            if (props.mapData.visibility)
               inputValues.visibility.value0 = inputValues.visibility.value = props.mapData.visibility;
            if (clone) {
               //복제 생성
               inputValues.splash.value = '';
               inputValues.title.value = inputValues.title.value + ' (copy)';
               inputValues.visibility.value = 'private';
               inputValues.desc.value0 = '';
               inputValues.map.value0 = '';
               inputValues.mapSize.value0 = 0;
               inputValues.category.value0 = '';
               inputValues.visibility.value0 = '';
            }
         } else if (props.newCategory) {
            //신규 생성 (카테고리 지정)
            if (!(props.newCategory === 'all' || props.newCategory === 'my'))
               inputValues.category.value = props.newCategory;
         }
         setRefresh(!refresh);
      }, []);

      function GetSaveData() {
         let saveData: any = null;
         for (const key in inputValues) {
            let v = (inputValues as any)[key];
            if (v.value !== v.value0) {
               if (saveData === null) saveData = {};
               saveData[key] = v.value;
            }
         }
         return saveData;
      }

      async function Submit() {
         console.log('Submit========');

         setShowError(true);
         // 입력값 검증
         for (const key in inputValues) {
            if ((inputValues as any)[key].error) {
               ToastM.Open(lang.common.입력값을확인하세요, 'error');
               return;
            }
         }

         // 변경값이 있으면 저장
         // let newInputValuesCheck =
         //    inputValues.title.value + inputValues.desc.value + inputValues.map.value + inputValues.size.value;
         let needReload = false;
         let newMapCreated = false;
         let mapID = props.currentMapId;
         let dataSave = GetSaveData();
         if (dataSave) {
            dataSave.ownerName = WRepository.user?.displayName ?? '';
            dataSave.ownerPhoto = WRepository.user?.photoURL ?? '';
         }

         await MyDebug.Task(lang.common.저장, async () => {
            if (dataSave) {
               if (props.currentMapId) {
                  // *변경 사항 저장
                  const dataSave2 = {
                     ...dataSave,
                  };
                  await WRepository.SaveMapInfo(props.currentMapId, dataSave2);
               } else {
                  // *맵 새로 만들기
                  const dataSave2 = {
                     ...dataSave,
                     owner: WRepository.user?.uid ?? '',
                     editor: [],
                     sort: 0,
                  };
                  let deco = {};
                  // 클론이면 기존 맵 데이터 복사
                  if (props.copyTargetMapId) {
                     //dataSave2.deco = props.map.deco;
                     let srcMapData = await WRepository.FindMapData(props.copyTargetMapId);
                     if (srcMapData) {
                        deco = srcMapData.data.deco;
                     }
                  }
                  mapID = await WRepository.CreateMap(dataSave2, deco);
                  if (mapID) {
                     ToastM.Open(MyStringUtil.Format(lang.w.xxx월드가생성되었습니다, dataSave2.title));
                     newMapCreated = true;
                  }
               }
               needReload = true;
            }

            if (mapID && imageDataURL) {
               await MyFirebaseM.StorageUploadBase64(`${mapID}/@mwm@splash.jpg`, imageDataURL);
               let url = await MyFirebaseM.StorageGetDownloadURL(`${mapID}/@mwm@splash.jpg`);
               if (url) {
                  if (await WRepository.SaveMapInfo(mapID, { splash: url })) {
                     if (!dataSave) dataSave = {};
                     dataSave.splash = url;
                     needReload = true;
                  }
               }
            }
         });

         if (newMapCreated) {
            dataSave = undefined;
            myHistory.push(`/play/${mapID}`);
            // 내 카테고리로 이동
            UIAction.SetCategory('my');
         }

         // 창 닫기
         ModalM.Close(dataSave ? { ...dataSave } : undefined);
         needReload && WRepository.LoadMapList();
         //WRepository.ForceUpdate();
      }

      let imgURL = imageDataURL || inputValues.splash.value;

      return (
         <Box sx={{ width: 500, maxWidth: '100%' }}>
            {/* 제목 */}
            {props.currentMapId ? (
               <WSubTitle>{lang.w.월드정보}</WSubTitle>
            ) : (
               <WSubTitle>{lang.w.새월드만들기}</WSubTitle>
            )}
            <Stack justifyContent={'center'} alignItems={'center'}>
               <Stack
                  className={'button'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  style={{
                     width: imgURL ? 'auto' : '100px',
                     height: imgURL ? '150px' : '100px',
                     color: 'rgb(180, 180, 180)',
                     border: '3px dotted rgb(180, 180, 180)',
                  }}
                  onClick={() => {
                     MsgBoxM.Open(
                        <WPageImageEdit
                           aspectW={1}
                           aspectH={1}
                           onComplete={(dataURL: string) => {
                              // 이미지 선택됨
                              setImageDataURL(dataURL);
                              setRefresh(!refresh);
                           }}
                        />,
                     );
                  }}
               >
                  {imgURL ? (
                     <img
                        src={imgURL}
                        alt={'MainImage'}
                        style={{
                           width: '100%',
                           height: '100%',
                        }}
                     />
                  ) : (
                     <>
                        <Typography>{lang.common.대표이미지}</Typography>
                        <Add />
                     </>
                  )}
               </Stack>
            </Stack>
            <Box p={1}>
               <ValidatedTextField
                  label={lang.common.제목}
                  fullWidth
                  validate
                  showError={showError}
                  min={1}
                  max={22}
                  value={inputValues.title.value}
                  onChange={(e) => {
                     let value = e.target.value;
                     if (value !== inputValues.title.value) {
                        inputValues.title.value = value;
                        setRefresh(!refresh);
                     }
                  }}
                  onValidate={(error) => {
                     inputValues.title.error = error;
                  }}
               />
            </Box>
            <Box p={1}>
               <ValidatedTextField
                  label={lang.common.설명}
                  fullWidth
                  validate
                  showError={showError}
                  min={1}
                  max={100}
                  value={inputValues.desc.value}
                  onChange={(e) => {
                     let value = e.target.value;
                     if (value !== inputValues.desc.value) {
                        //중복된 개행 문자 제거
                        inputValues.desc.value = value.replace('\n\n', '\n');
                        setRefresh(!refresh);
                     }
                  }}
                  onValidate={(error) => {
                     inputValues.desc.error = error;
                  }}
                  multiline
               />
            </Box>
            <Box p={0} sx={{ textAlign: 'left' }}>
               <Box pl={1} display={'inline-block'}>
                  <FormLabel>{lang.w.맵종류}</FormLabel>
                  <Box p={1} display={'inline-block'}>
                     <Select
                        // disabled
                        sx={{ height: wconfig.ui.셀렉트높이 }}
                        value={inputValues.map.value}
                        autoWidth
                        onChange={(e) => {
                           inputValues.map.value = e.target.value as string;
                           setRefresh(!refresh);
                        }}
                     >
                        <MenuItem value="mapBlock001">{lang.w.맵블록001}</MenuItem>
                        <MenuItem value="mapEmpty001">{lang.w.맵빈공간001}</MenuItem>
                        <MenuItem sx={{ display: 'none' }} value="mapGallery001">
                           mapGallery001
                        </MenuItem>
                     </Select>
                  </Box>
               </Box>
               <Box pl={1} display={'inline-block'}>
                  <FormLabel>{lang.w.맵크기}</FormLabel>
                  <Box p={1} display={'inline-block'}>
                     <Select
                        sx={{ height: wconfig.ui.셀렉트높이 }}
                        value={inputValues.mapSize.value}
                        autoWidth
                        onChange={(e) => {
                           inputValues.mapSize.value = parseInt(e.target.value.toString());
                           setRefresh(!refresh);
                        }}
                     >
                        <MenuItem value="50">50</MenuItem>
                        <MenuItem value="100">100</MenuItem>
                        {/* <MenuItem value="size200">200</MenuItem> */}
                     </Select>
                  </Box>
               </Box>
               <Box pl={1} display={'inline-block'}>
                  <FormLabel>{lang.common.카테고리}</FormLabel>
                  <Box p={1} display={'inline-block'}>
                     <Select
                        sx={{ height: wconfig.ui.셀렉트높이 }}
                        value={inputValues.category.value}
                        autoWidth
                        onChange={(e) => {
                           inputValues.category.value = e.target.value;
                           setRefresh(!refresh);
                        }}
                     >
                        <MenuItem value="etc">{lang.common.etc}</MenuItem>
                        <MenuItem value="exhibition">{lang.common.exhibition}</MenuItem>
                        <MenuItem value="event">{lang.common.event}</MenuItem>
                        <MenuItem value="homepage">{lang.common.homepage}</MenuItem>
                        {/* <MenuItem value="game">{lang.common.game}</MenuItem> */}
                     </Select>
                  </Box>
               </Box>
               <Box pl={1} display={'inline-block'}>
                  <FormLabel>{lang.common.공개여부}</FormLabel>
                  <Box p={1} display={'inline-block'}>
                     <Select
                        sx={{ height: wconfig.ui.셀렉트높이 }}
                        // disabled
                        value={inputValues.visibility.value}
                        autoWidth
                        onChange={(e) => {
                           inputValues.visibility.value = e.target.value;
                           setRefresh(!refresh);
                        }}
                     >
                        <MenuItem value="private">{lang.common.비공개}</MenuItem>
                        <MenuItem value="friends">{lang.common.친구만공개}</MenuItem>
                        <MenuItem value="public">{lang.common.공개}</MenuItem>
                     </Select>
                  </Box>
               </Box>
            </Box>
            <Box p={0} sx={{ textAlign: 'right' }}>
               {props.currentMapId && (
                  <CButton
                     sx={{ fontSize: '1em', m: 0.5 }}
                     color={'secondary'}
                     onClick={async () => {
                        let ret = await MsgBoxM.Open(
                           lang.common.정말삭제하시겠습니까,
                           lang.common.확인,
                           lang.common.취소,
                        );
                        if (ret.btn === lang.common.확인) {
                           if (await WRepository.DeleteMap(props.currentMapId!)) {
                              ToastM.Open(lang.common.삭제완료);
                              myHistory.push(`/`);
                              MySystem.ForceUpdate();
                              ModalM.Close();
                           }
                        }
                     }}
                  >
                     <Delete color="error" sx={{ fontSize: '1.9em' }} />
                  </CButton>
               )}
               {isDataChanged && (
                  <CButton
                     color={isDataChanged ? 'primary' : 'secondary'}
                     sx={{ fontSize: '1.1em', m: 0.5 }}
                     // startIcon={<Check />}
                     onClick={Submit}
                  >
                     {props.currentMapId ? lang.common.저장 : lang.common.만들기}
                  </CButton>
               )}
               {props.currentMapId && (
                  <CButton
                     sx={{ fontSize: '1.1em', m: 0.5 }}
                     startIcon={<Build />}
                     onClick={async () => {
                        await Submit();
                        if (props.cbEditWorld) props.cbEditWorld();
                        // else WSystem.OpenMap(props.currentMapId!, 'edit');
                     }}
                  >
                     {lang.w.건설모드실행}
                  </CButton>
               )}
            </Box>
         </Box>
      );
   },
);
