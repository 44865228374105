import { More, OpenInNew } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { memo, useEffect } from 'react';
import { CButton } from '../../common/components/CButton';
import { ModalM } from '../../common/components/msgbox/ModalM';
import { lang } from '../../common/config/lang';
import { MySystem } from '../../common/util/MySystem';
import { wconfig } from '../config/wconfig';

export interface WImageViwerData {
   title?: string;
   img?: string;
   imgType?: string;
   desc?: string;
   link?: string;
   linkType?: string;
}

export const WPageImageViewer = memo((props: { data: WImageViwerData }) => {
   useEffect(() => {}, [props.data]);

   return (
      <Stack
         direction={'row'}
         justifyContent="center"
         alignItems="center"
         sx={{
            bgcolor: 'rgba(0,0,0,0.9)',
            width: '100vw',
            height: '100vh',
         }}
      >
         <Stack
            direction={'column'}
            justifyContent="center"
            alignItems="center"
            spacing={1}
            sx={{
               // bgcolor: 'black',
               // boxShadow: '0 0 50px 0 rgba(0,0,0,1)',
               color: 'white',
               p: 1,
               // objectFit: 'contain',
               // maxWidth: 'calc(100vw - 200px)',
               // borderRadius: 0.2,
               // border: '1px solid rgba(0,0,0,0.1)',
               // width: '100%',
               // height: '100%',
               maxHeight: '100vh',
               maxWidth: wconfig.ui.프레임최고넓이,
               // maxWidth: '100vh',
               // height: 'auto',
               // overflow: 'auto',
               // bgcolor: 'red',
               // borderRadius: 0.2,
            }}
         >
            {/* <img src={props.data.img} alt={props.data.title} style={{ height: '100vh', minWidth: '50%', maxWidth: '100%' }} /> */}
            {/* <SMJsonPage data={props.data} /> */}

            {/* <picture>
            <source srcSet={props.data.img} media="(max-width: 768px)" />
            <img src={props.data.img} alt="logo" />
         </picture> */}
            {/* <Box sx={{
            objectFit: 'contain',
            width: 500,
            height: 500,
         }}> */}
            <img
               src={props.data.img}
               alt={props.data.title}
               style={{
                  // backgroundColor: 'red',
                  width: '100%',
                  // width: `min(100%, calc(100vh - 200px))`,
                  height: '100%',
                  maxWidth: wconfig.ui.이미지최고넓이,
                  maxHeight: `min(${wconfig.ui.이미지최고높이}px, calc(100vh - 180px))`,
                  objectFit: 'contain',
               }}
               // style={{ width: 'auto', height: 'auto', minWidth: '50%', minHeight: '50%', maxWidth: '100%', maxHeight: '100%' }}
            />
            {/* </Box> */}
            <Box overflow={'auto'}>
               <Typography m={0} fontSize={'1.3em'}>
                  {props.data.title}
               </Typography>
               <Box m={0} maxWidth={wconfig.ui.이미지최고높이} textAlign={'left'} whiteSpace={'pre-wrap'}>
                  {props.data.desc}
               </Box>
            </Box>
            <Stack width="100%" direction={'row'} justifyContent={'right'} spacing={1}>
               {props.data.link && (
                  <CButton
                     startIcon={<OpenInNew />}
                     onClick={() => {
                        if (props.data.link) MySystem.OpenUrl(props.data.link, true);
                     }}
                  >
                     <Typography noWrap>{lang.common.더보기}</Typography>
                  </CButton>
               )}
               <CButton
                  onClick={() => {
                     ModalM.Close();
                  }}
               >
                  <Typography noWrap>{lang.common.닫기}</Typography>
               </CButton>
            </Stack>
         </Stack>
      </Stack>
   );
});
