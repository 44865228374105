import { MyStringUtil } from '../base/MyStringUtil';
import { MyUtil } from '../base/MyUtil';
import { ToastM } from '../components/toast/ToastM';
import { config } from '../config/config';
import { MyConsoleManager } from '../manager/MyConsoleManager';
import { MyTaskM } from '../manager/MyTaskM';
import { MySystem } from './MySystem';

/**
 * 디버깅 관리자
 * @author elee
 * @date 2021-04-13,
 */
export class MyDebug {
   /**
    * 어설트
    * 앱 진행 불가
    * assert 대신 사용한다.
    * assert를 사용하려면 "react-scripts": "4.0.3", 을 사용해야 한다.
    */
   static Assert(con: any, errorText?: string): boolean {
      const condition = !!con;
      if (!condition) {
         errorText = 'assert: ' + errorText;
         throw new Error(errorText);
      }
      return condition;
   }

   /**
    * 에러 로그
    * 앱 진행 불가
    */
   static Error(log: any) {
      console.error(log);
      let msg = MyStringUtil.StringVal(log);
      MyConsoleManager.Log('error: ' + msg);
      ToastM.Open(msg, 'error');
   }

   /** 콘솔 debug 로그 */
   static Debug(log: any) {
      if (this.IsDebug()) this.Log('debug: ' + MyStringUtil.StringVal(log));
   }

   /** 로그 */
   static Log(log: any, id?: number): number {
      console.log(log);
      return MyConsoleManager.Log(log, id);
   }

   static LogClear() {
      MyConsoleManager.LogClear();
   }

   /** 태스크 */
   static #taskCnt = 0;
   static async Task(msg: string, cb: () => Promise<void>, minimumMillis = 1000) {
      let handle = MyStringUtil.UUID();
      let isDelayMode = this.#taskCnt < 4 && minimumMillis > 0;
      let startTime = isDelayMode ? MySystem.Now() : 0;
      ++this.#taskCnt;
      MyTaskM.Start(handle, false, (msg = msg.endsWith('...') ? msg : msg + '...'));
      try {
         await cb();
      } catch (e) {
         MyDebug.Error(e);
         throw e;
      } finally {
         MySystem.ForceUpdate();
         if (isDelayMode) {
            let duration = MySystem.Now() - startTime;
            if (duration < minimumMillis) await MyUtil.Wait(minimumMillis - duration);
         }
         --this.#taskCnt;
         MyTaskM.Process(handle);
      }
   }

   static async WaitTask() {
      while (this.#taskCnt > 0) {
         await MyUtil.Wait();
      }
   }   

   static IsDebug() {
      // return MySystem.IsDevelopBuild();
      return config.DEBUG;
   }
}

if (!MySystem.IsDevelopBuild()) {
   console.log = function () {};
}
