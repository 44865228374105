import 'core-js/stable';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'regenerator-runtime/runtime';

import { createRoot } from 'react-dom/client';
import { Provider, useSelector } from 'react-redux';
import { Route, Router, Switch } from 'react-router';

import { myHistory } from './common/base/myHistory';
import { reduxStore } from './common/reducer/reduxStore';
import { RootStateType } from './common/reducer/RootReducer';
import { MyDebug } from './common/util/MyDebug';
import { PMain } from './p/page/PMain';
import { test } from './test';
import { wconfig } from './w/config/wconfig';
import { WMain } from './w/page/WMain';
import { WPagePortal } from './w/page/WPagePortal';
import { useEffect } from 'react';
import { MySystem } from './common/util/MySystem';
import { config } from './common/config/config';
import { WSystem } from './w/manager/WSystem';

const Index = () => {
   // useSelector((state: RootStateType) => state.ui.language);
   useSelector((state: RootStateType) => state.ui.forceUpdate);

   useEffect(() => {
      window.addEventListener('resize', HandleResize);
      window.addEventListener('keydown', HandleKeydown);
      return () => {
         window.removeEventListener('resize', HandleResize);
         window.removeEventListener('keydown', HandleKeydown);
      };
   }, []);

   let pathParameter: string = window.location.pathname;
   let path = pathParameter.split('/').filter((x) => x.length > 0);
   let page = path[0];
   let contentId = path[1];
   let mode = path[2];

   return (
      <div
         style={{
            width: '100vw',
            minWidth: 0,
            height: '100vh',
            margin: 0,
            padding: 0
         }}
      >
         {wconfig.env.plusdata ? (
            <PMain />
         ) : (
            <Router history={myHistory}>
               <Switch>
                  {/* 포털 */}
                  <Route exact path="/">
                     <WPagePortal />
                  </Route>
                  {/* 나머지 */}
                  <Route path="*">
                     <WMain page={page} contentId={contentId} mode={mode} />
                  </Route>
               </Switch>
            </Router>
         )}
      </div>
   );

   function HandleResize() {
      MySystem.ForceUpdate();
   }

   function HandleKeydown(e: any) {
      //! 단축키 처리
      if (MySystem.IsDevelopBuild()) {
         if (navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey) {
            if (!e.repeat) {
               if (config.DEBUG && e.key === 'i') {
                  //ctrl+i
                  //변수 목록
                  e.preventDefault();
                  WSystem.ShowAllVariables();
               } else if (e.key === 'd') {
                  //ctrl+d
                  //디버깅 온오프
                  e.preventDefault();
                  config.DEBUG = !config.DEBUG;
                  MySystem.ForceUpdate();
               }
            }
         }
      }
   }
};

const container: any = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
   <Provider store={reduxStore}>
      {/* <ErrorBoundary> */}
      <Index />
      {/* </ErrorBoundary> */}
   </Provider>,
);

if (MyDebug.IsDebug()) test();
