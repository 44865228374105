import { memo, useState } from 'react';

import { Box, Stack, Typography } from '@mui/material';

import { ThumbDownAlt, ThumbDownOffAlt, ThumbUpAlt, ThumbUpOffAlt } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import React from 'react';
import { useSelector } from 'react-redux';
import { CButton } from '../../common/components/CButton';
import { ModalM } from '../../common/components/msgbox/ModalM';
import { MsgBoxM } from '../../common/components/msgbox/MsgBoxM';
import { ValidatedTextField } from '../../common/components/validate/ValidatedTextField';
import { lang } from '../../common/config/lang';
import { MyFirebaseM } from '../../common/manager/MyFirebaseM';
import { RootStateType } from '../../common/reducer/RootReducer';
import { MySystem } from '../../common/util/MySystem';
import { WAvatar } from '../components/WAvatar';
import { WReplyData, WRepository } from '../manager/WRepository';
import { WSystem } from '../manager/WSystem';
import { WReply } from './WPageReply';

export const WPageReplyList = memo(
   (propsPage: {
      parent?: { uid: string; data: WReplyData };
      parentId: string;
      mapId: string;
      cbDelete?: () => void;
      cbCreate?: () => void;
   }) => {
      useSelector((state: RootStateType) => state.ui.forceUpdate);
      const [loaded, setLoaded] = useState(false);
      const [inputText, setInputText] = useState('');
      const [replyList, setReplyList] = useState<{ uid: string; data: WReplyData }[]>([]);
      const liked = WRepository.IsLiked(propsPage.parentId);

      async function LoadReplyList() {
         let _replyList = await WRepository.FindReplyList(propsPage.parentId);
         if (_replyList) setReplyList(_replyList as { uid: string; data: WReplyData }[]);
         setLoaded(true);
         MySystem.ForceUpdate();
      }

      React.useEffect(() => {
         LoadReplyList();
      }, [propsPage.parentId, propsPage.mapId]);

      let parentNode = propsPage.parent?.data;
      let parentType = 'reply';
      if (!parentNode) {
         parentNode = WRepository.map?.data;
         parentType = 'map';
      }

      if (!loaded) return <></>;
      return (
         <Box width="100%" textAlign={'left'}>
            {propsPage.parent && (
               <Box mb={0.5} bgcolor={grey[200]} borderRadius={1}>
                  <WReply
                     reply={propsPage.parent}
                     cbDelete={() => {
                        ModalM.Close();
                     }}
                     propsPage={propsPage}
                     LoadReplyList={LoadReplyList}
                  />
               </Box>
            )}
            <Stack direction={'row'} spacing={0}>
               <Box p={0.5}>
                  <Typography>
                     {lang.common.댓글} {parentNode?.replyCount || 0}
                  </Typography>
               </Box>
               <Box flex={1} />
               {!propsPage.parent && (
                  <>
                     <CButton
                        size="small"
                        variant={'text'}
                        startIcon={liked === true ? <ThumbUpAlt /> : <ThumbUpOffAlt />}
                        onClick={async () => {
                           WRepository.UpdateLike(
                              propsPage.parentId,
                              true,
                              liked === true,
                              liked === false,
                              parentNode,
                              parentType,
                           );
                        }}
                     >
                        {parentNode?.like || 0}
                     </CButton>
                     <CButton
                        size="small"
                        variant={'text'}
                        startIcon={liked === false ? <ThumbDownAlt /> : <ThumbDownOffAlt />}
                        onClick={async () => {
                           WRepository.UpdateLike(
                              propsPage.parentId,
                              false,
                              liked === true,
                              liked === false,
                              parentNode,
                              parentType,
                           );
                        }}
                     >
                        {parentNode?.dislike || 0}
                     </CButton>
                  </>
               )}
            </Stack>
            {/* 댓글 달기 */}
            {/* {WRepository.user && ( */}
            <Box p={0.5}>
               <Stack direction="row" alignItems="center" spacing={1} justifyContent="center">
                  {WRepository.user && (
                     <WAvatar
                        shortWidth
                        photo={WRepository.user.photoURL || ''}
                        onClick={() => {
                           WRepository.user?.uid && WSystem.OpenUserInfoWindowById(WRepository.user.uid);
                        }}
                     />
                  )}
                  <ValidatedTextField
                     multiline
                     style={{ flex: 1 }}
                     value={inputText}
                     label={lang.common.댓글추가}
                     onChange={async (evt: any) => {
                        if (!WRepository.user) {
                           let ret = await MsgBoxM.Open(
                              lang.common.로그인이필요합니다,
                              lang.common.로그인,
                              lang.common.취소,
                           );
                           if (ret.btn === lang.common.로그인) {
                              MyFirebaseM.Login();
                           }
                           return;
                        }
                        setInputText(evt.target.value);
                     }}
                  />
               </Stack>
               {inputText.trim().length > 0 ? (
                  <Stack pt={1} direction="row" alignItems="center" spacing={1} justifyContent="end">
                     <CButton
                        size={'small'}
                        // disabled={inputText.trim().length === 0}
                        onClick={async () => {
                           setInputText('');
                        }}
                     >
                        {lang.common.취소}
                     </CButton>
                     <CButton
                        size={'small'}
                        // disabled={inputText.trim().length === 0}
                        onClick={async () => {
                           if (WRepository.user) {
                              let replyData: WReplyData = {
                                 parentId: propsPage.parentId,
                                 owner: WRepository.user.uid || '',
                                 ownerName: WRepository.user.displayName || '',
                                 ownerPhoto: WRepository.user.photoURL || '',
                                 text: inputText.trim(),
                              };
                              if (propsPage.mapId) replyData.mapId = propsPage.mapId;
                              if (await WRepository.CreateReply(replyData)) {
                                 propsPage.cbCreate?.();
                                 //부모 기준에서 댓글 개수 증가
                                 if (propsPage.parent) {
                                    propsPage.parent.data.replyCount = propsPage.parent.data.replyCount || 0;
                                    ++propsPage.parent.data.replyCount;
                                 }
                                 LoadReplyList();
                              }
                           }
                           setInputText('');
                        }}
                     >
                        {lang.common.확인}
                     </CButton>
                  </Stack>
               ) : (
                  <Box p={0.5} />
               )}
            </Box>
            {/* )} */}
            <Box width={'100%'} pl={1}>
               {replyList.map((reply) => (
                  <WReply key={reply.uid} reply={reply} propsPage={propsPage} LoadReplyList={LoadReplyList} />
               ))}
            </Box>
         </Box>
      );
   },
);
