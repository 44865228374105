import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Check, List, ThumbUpAlt, ThumbUpOffAlt } from '@mui/icons-material';
import { Box, FormLabel, MenuItem, Select, Typography } from '@mui/material';
import { blue } from '@mui/material/colors';

import { CButton } from '../../common/components/CButton';
import { MsgBoxM } from '../../common/components/msgbox/MsgBoxM';
import { ToastM } from '../../common/components/toast/ToastM';
import { ValidatedTextField } from '../../common/components/validate/ValidatedTextField';
import { lang } from '../../common/config/lang';
import { RootStateType } from '../../common/reducer/RootReducer';
import { WAvatar } from '../components/WAvatar';
import { WSubTitle } from '../components/WTitle';
import { WRepository, WUser } from '../manager/WRepository';
import { myHistory } from '../../common/base/myHistory';
import { MySystem } from '../../common/util/MySystem';

// let inputValuesCheck: any = {};
let inputValues = resetValues();
function resetValues() {
   return {
      photoURL: {
         value: '',
         error: false,
         value0: '',
      },
      displayName: {
         value: '',
         error: false,
         value0: '',
      },
      uid: {
         value: '',
         error: false,
         value0: '',
      },
      email: {
         value: '',
         error: false,
         value0: '',
      },
      emailVisibility: {
         value: 'public',
         error: false,
         value0: '',
      },
      desc: {
         value: '',
         error: false,
         value0: '',
      },
      authority: {
         value: 'basic',
         error: false,
         value0: '',
      },
   };
}

export const WPageUserInfo = memo((props: { userData: WUser; isPopup?: boolean }) => {
   useSelector((state: RootStateType) => state.ui.forceUpdate);
   const [refresh, setRefresh] = useState(false);
   const [showError, setShowError] = useState(false);
   const isMyUserInfo = props.userData.uid === WRepository.user?.uid;
   const isDataChanged = !!GetSaveData();
   const liked = WRepository.IsLiked(props.userData.uid);

   useEffect(() => {
      inputValues = resetValues();
      if (props.userData) {
         inputValues.photoURL.value0 = inputValues.photoURL.value = props.userData.photoURL ?? '';
         inputValues.displayName.value0 = inputValues.displayName.value = props.userData.displayName ?? '';
         inputValues.uid.value0 = inputValues.uid.value = props.userData.uid ?? '';
         inputValues.email.value0 = inputValues.email.value = props.userData.email ?? '';
         inputValues.desc.value0 = inputValues.desc.value = props.userData.desc ?? '';
         if (props.userData.emailVisibility)
            inputValues.emailVisibility.value0 = inputValues.emailVisibility.value =
               props.userData.emailVisibility;
         if (props.userData.authority)
            inputValues.authority.value0 = inputValues.authority.value = props.userData.authority;
      }
      // inputValuesCheck = inputValues.displayName.value + inputValues.desc.value;
      setRefresh(!refresh);
   }, []);

   function GetSaveData() {
      let saveData: any = null;
      for (const key in inputValues) {
         let v = (inputValues as any)[key];
         if (v.value !== v.value0) {
            if (saveData === null) saveData = {};
            saveData[key] = v.value;
         }
      }
      return saveData;
   }

   async function Submit() {
      if (isMyUserInfo && isDataChanged) {
         setShowError(true);

         // 입력값 검증
         for (const key in inputValues) {
            if ((inputValues as any)[key].error) {
               ToastM.Open(lang.common.입력값을확인하세요, 'error');
               return;
            }
         }

         // 값 변화된 값만 저장
         let saveData = GetSaveData();
         if (saveData) {
            await WRepository.SaveMyInfo(saveData);
         }

         // 변화된 값 동기화
         for (const key in inputValues) {
            let v = (inputValues as any)[key];
            v.value0 = v.value;
         }
      }

      if (props.isPopup) {
         MsgBoxM.Close();
      }
   }

   return (
      <Box sx={{ width: 500, maxWidth: '100%' }}>
         {/* 제목 */}
         {props.isPopup && <WSubTitle>{lang.common.유저정보}</WSubTitle>}
         {/* 아바타 */}
         <Box p={2} pt={0}>
            <WAvatar photo={props.userData.photoURL ?? undefined} size={50}>
               <>
                  <Typography fontWeight="bold">{inputValues.displayName.value}</Typography>
                  <Box
                     display="inline-block"
                     m={0.5}
                     p={0.5}
                     pt={0.1}
                     pb={0.1}
                     borderRadius={5}
                     bgcolor={blue[200]}
                     fontSize={'0.6em'}
                  >
                     {inputValues.authority.value}
                  </Box>
                  <CButton
                     size="small"
                     variant={'text'}
                     startIcon={liked === true ? <ThumbUpAlt /> : <ThumbUpOffAlt />}
                     onClick={async () => {
                        WRepository.UpdateLike(
                           props.userData.uid || '',
                           true,
                           liked === true,
                           liked === false,
                           props.userData,
                           'user',
                        );
                     }}
                  >
                     {props.userData?.like || 0}
                  </CButton>
               </>
            </WAvatar>
         </Box>
         {/* 조하요 */}

         {isMyUserInfo && (
            <Box p={1}>
               <ValidatedTextField
                  label={lang.common.아이디}
                  disabled
                  fullWidth
                  value={inputValues.uid.value}
               />
            </Box>
         )}
         {
            /*isMyUserInfo || inputValues.emailVisibility.value === 'public' &&*/ <Box p={1}>
               <ValidatedTextField
                  label={lang.common.이메일}
                  disabled
                  fullWidth
                  value={
                     isMyUserInfo ? inputValues.email.value : inputValues.email.value.substring(0, 4) + '****'
                  }
               />
            </Box>
         }
         <Box p={1}>
            <ValidatedTextField
               label={lang.common.닉네임}
               disabled={!isMyUserInfo}
               fullWidth
               validate
               showError={showError}
               min={1}
               max={15}
               value={inputValues.displayName.value}
               onChange={(e) => {
                  let value = e.target.value;
                  if (value !== inputValues.displayName.value) {
                     inputValues.displayName.value = value;
                     setRefresh(!refresh);
                  }
               }}
               onValidate={(error) => {
                  inputValues.displayName.error = error;
               }}
            />
         </Box>
         <Box p={1}>
            <ValidatedTextField
               label={lang.common.자기소개}
               disabled={!isMyUserInfo}
               fullWidth
               validate
               showError={showError}
               min={0}
               max={200}
               value={inputValues.desc.value}
               onChange={(e) => {
                  let value = e.target.value;
                  if (value !== inputValues.desc.value) {
                     //중복된 개행 문자 제거
                     inputValues.desc.value = value.replace('\n\n', '\n');
                     setRefresh(!refresh);
                  }
               }}
               onValidate={(error) => {
                  inputValues.desc.error = error;
               }}
               multiline
            />
         </Box>
         <Box p={0} sx={{ textAlign: 'right' }}>
            {/* {isMyUserInfo && (
               <Box p={1} display={'inline-block'}>
                  <FormLabel>{lang.common.이메일공개}</FormLabel>
                  <Box p={1} display={'inline-block'}>
                     <Select
                        // disabled
                        size={'small'}
                        id="map"
                        value={inputValues.emailVisibility.value}
                        autoWidth
                        onChange={(e) => {
                           inputValues.emailVisibility.value = e.target.value;
                           setRefresh(!refresh);
                        }}
                     >
                        <MenuItem value="private">{lang.common.비공개}</MenuItem>
                        <MenuItem value="friends">{lang.common.친구만공개}</MenuItem>
                        <MenuItem value="public">{lang.common.공개}</MenuItem>
                     </Select>
                  </Box>
               </Box>
            )} */}
            {props.isPopup && (
               <CButton
                  color={'primary'}
                  sx={{ fontSize: '1.1em', m: 0.5 }}
                  startIcon={<List />}
                  onClick={() => {
                     myHistory.push('/channel/' + props.userData.uid);
                     MsgBoxM.Close();
                     MySystem.ForceUpdate();
                  }}
               >
                  {lang.common.채널보기}
               </CButton>
            )}
            {isMyUserInfo && isDataChanged && (
               <CButton
                  color={'primary'}
                  sx={{ fontSize: '1.1em', m: 0.5 }}
                  startIcon={<Check />}
                  onClick={Submit}
               >
                  {lang.common.저장}
               </CButton>
            )}
         </Box>
      </Box>
   );
});
