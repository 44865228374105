import { ArrowBackIos } from '@mui/icons-material';
import { Box, IconButton, Stack } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { myHistory } from '../../common/base/myHistory';
import { RootStateType } from '../../common/reducer/RootReducer';
import { MySystem } from '../../common/util/MySystem';
import { WMapList } from '../components/WMapList';
import { WRepository, WUser } from '../manager/WRepository';
import { WPageUserInfo } from './WPageUserInfo';

export const WPageChannel = memo((props: { userId: string; mode?: string }) => {
   useSelector((state: RootStateType) => state.ui.forceUpdate);
   const [userData, setUserData] = useState<WUser | null>(null);
   const [mapList, setMapList] = useState<{ uid: string; data: any }[]>([]);
   const [close, setClose] = useState(false);

   useEffect(() => {
      WRepository.FindUserInfo(props.userId).then((result) => {
         result && setUserData(result.data);
      });
      WRepository.FindUserMapList(props.userId).then((result) => {
         result && setMapList(result);
      });
   }, [props.userId]);

   return (
      <Stack
         className={close ? 'toRight' : 'fromRight'}
         position={'fixed'}
         direction={'column'}
         width={'100%'}
         height={'100%'}
         alignItems={'center'}
         bgcolor="white"
         zIndex={1}
         overflow={'auto'}
         textAlign={'center'}
      >
         {/* 상단바 */}
         <Stack p={0.5} direction={'row'} spacing={1} justifyContent={'end'} width="100%">
            <IconButton
               children={<ArrowBackIos />}
               color={'primary'}
               sx={{ fontSize: '0.9em' }}
               onClick={() => {
                  setClose(true);
                  setTimeout(() => {
                     myHistory.push('/');
                     MySystem.ForceUpdate();
                  }, 500);
               }}
            />
            <Box flex={1}></Box>
         </Stack>
         {/* 유저 정보 */}
         {userData && <WPageUserInfo userData={userData} />}
         {/* 맵 리스트 */}
         <Box p={2}>
            <WMapList mapList={mapList} />
         </Box>
      </Stack>
   );
});
