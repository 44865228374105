import React, { useRef, useState } from 'react';

import ReactCrop, { centerCrop, Crop, makeAspectCrop, PixelCrop } from 'react-image-crop';
import { canvasPreview } from './canvasPreview';
import { useDebounceEffect } from './useDebounceEffect';

import { Box, Stack } from '@mui/material';
import 'react-image-crop/dist/ReactCrop.css';
import { WSubTitle } from '../../../w/components/WTitle';
import { wconfig } from '../../../w/config/wconfig';
import { MyBrowserUtil } from '../../base/MyBrowserUtil';
import { MyStringUtil } from '../../base/MyStringUtil';
import { lang } from '../../config/lang';
import { CButton } from '../CButton';
import { MsgBoxM } from '../msgbox/MsgBoxM';
import { ToastM } from '../toast/ToastM';

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
   return centerCrop(
      makeAspectCrop(
         {
            unit: '%',
            width: 100,
         },
         aspect,
         mediaWidth,
         mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
   );
}

export const WPageImageEdit = (props: {
   dataURL?: string;
   aspectW: number;
   aspectH: number;
   onComplete: (dataURL: string) => void;
}) => {
   const [imgSrc, setImgSrc] = useState(props.dataURL);
   const previewCanvasRef = useRef<HTMLCanvasElement>(null);
   const imgRef = useRef<HTMLImageElement>(null);
   const [crop, setCrop] = useState<Crop>();
   const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
   const [scale, setScale] = useState(1);
   const [rotate, setRotate] = useState(0);
   const [aspect, setAspect] = useState<number | undefined>(props.aspectW / props.aspectH);
   const [dataURL, setDataURL] = useState('');

   // function onSelectFile2(e: React.ChangeEvent<HTMLInputElement>) {
   //    if (e.target.files && e.target.files.length > 0) {
   //       let file = e.target.files[0];
   //       onSelectFile(file);
   //    }
   // }

   function onSelectFile(file: File) {
      if (file.size > wconfig.system.최대업로드파일크기 * 1024 * 1024) {
         ToastM.Open(
            MyStringUtil.Format(
               lang.common.XXXMB이하파일만업로드가능합니다,
               wconfig.system.최대업로드파일크기,
            ),
         );
      } else {
         setCrop(undefined); // Makes crop preview update between images.
         const reader = new FileReader();
         reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''));
         reader.readAsDataURL(file);
         // console.log('onSelectFile', file);
      }
   }

   function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
      if (aspect) {
         const { width, height } = e.currentTarget;
         setCrop(centerAspectCrop(width, height, aspect));
      }
   }

   useDebounceEffect(
      async () => {
         if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
            // We use canvasPreview as it's much faster than imgPreview.
            canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate);
            setDataURL(previewCanvasRef.current.toDataURL('image/jpeg'));
         }
      },
      100,
      [completedCrop, scale, rotate],
   );

   // function handleToggleAspectClick() {
   //    if (aspect) {
   //       setAspect(undefined);
   //    } else if (imgRef.current) {
   //       const { width, height } = imgRef.current;
   //       setAspect(16 / 9);
   //       setCrop(centerAspectCrop(width, height, 16 / 9));
   //    }
   // }

   // function handleDownloadFile() {
   //    let fileName = 'crop.jpg';
   //    let blob = MyStringUtil.Base64toBlob(dataURL);
   //    MyBrowserUtil.SaveBlobToFile(blob, fileName);
   // }

   function Submit() {
      // let fileName = 'crop.jpg';
      // let blob = MyStringUtil.Base64toBlob(dataURL);
      // MyBrowserUtil.SaveBlobToFile(blob, fileName);
      props.onComplete(dataURL);
      MsgBoxM.Close();
   }

   return (
      <Box>
         <WSubTitle>이미지 선택</WSubTitle>
         {/* <div className="Crop-Controls">
            <input id='' type="file" accept="image/*" onChange={onSelectFile2} />
            <div>
               <label htmlFor="scale-input">Scale: </label>
               <input
                  id="scale-input"
                  type="number"
                  step="0.1"
                  value={scale}
                  disabled={!imgSrc}
                  onChange={(e) => setScale(Number(e.target.value))}
               />
            </div>
            <div>
               <label htmlFor="rotate-input">Rotate: </label>
               <input
                  id="rotate-input"
                  type="number"
                  value={rotate}
                  disabled={!imgSrc}
                  onChange={(e) => setRotate(Math.min(180, Math.max(-180, Number(e.target.value))))}
               />
            </div>
            <div>
               <button onClick={handleToggleAspectClick}>Toggle aspect {aspect ? 'off' : 'on'}</button>
            </div>
            <div>
               <button onClick={handleDownloadFile}>Download file</button>
            </div>
         </div> */}
         {Boolean(imgSrc) && (
            <ReactCrop
               crop={crop}
               onChange={(_, percentCrop) => setCrop(percentCrop)}
               onComplete={(c) => setCompletedCrop(c)}
               aspect={aspect}
            >
               <img
                  ref={imgRef}
                  alt="Crop me"
                  src={imgSrc}
                  style={{
                     width: '100%',
                     height: '100%',
                     maxWidth: wconfig.ui.이미지최고넓이,
                     maxHeight: `min(${wconfig.ui.이미지최고높이}px, calc(100vh - 180px))`,
                     objectFit: 'contain',
                     transform: `scale(${scale}) rotate(${rotate}deg)`,
                  }}
                  onLoad={onImageLoad}
               />
            </ReactCrop>
         )}
         {/* 숨김 캔바스 */}
         <Box>
            {Boolean(completedCrop) && (
               <canvas
                  ref={previewCanvasRef}
                  style={{
                     display: 'none',
                     border: '1px solid black',
                     objectFit: 'contain',
                     width: completedCrop?.width,
                     height: completedCrop?.height,
                  }}
               />
            )}
         </Box>
         {/* 버튼들 */}
         <Stack p={0.5} direction={'row'} spacing={1} justifyContent={'center'} alignItems={'center'}>
            {!props.dataURL && (
               <CButton
                  onClick={async () => {
                     let file = await MyBrowserUtil.SelectFile();
                     onSelectFile(file as File);
                  }}
               >
                  {lang.common.파일선택}
               </CButton>
            )}
            <CButton onClick={Submit}>{lang.common.확인}</CButton>
         </Stack>
      </Box>
   );
};
