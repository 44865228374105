import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { Add } from '@mui/icons-material';
import { Box } from '@mui/material';

import { MyStringUtil } from '../../common/base/MyStringUtil';
import { MyUtil } from '../../common/base/MyUtil';
import { CButton } from '../../common/components/CButton';
import { MsgBoxM } from '../../common/components/msgbox/MsgBoxM';
import { lang, langM } from '../../common/config/lang';
import { MyTaskM } from '../../common/manager/MyTaskM';
import { RootStateType } from '../../common/reducer/RootReducer';
import { UIAction } from '../../common/reducer/UIReducer';
import { WMapList } from '../components/WMapList';
import { WRepository } from '../manager/WRepository';
import { WSystem } from '../manager/WSystem';

export const WPageRoomList = memo((props: { category: string }) => {
   useSelector((state: RootStateType) => state.ui.forceUpdate);

   React.useEffect(() => {
      WRepository.LoadMapList(props.category);
   }, [props.category]);

   return (
      <Box p={1} {...props}>
         {/* {props.category === 'my' && <Box pb={2}>
            <CButton
               startIcon={<Add />}
               color={'secondary'}
               onClick={() => {
                  WSystem.OpenRoomCreateWindow();
               }}
            >
               {lang.w.새월드만들기}
            </CButton>
         </Box>} */}
         {/* 제목 */}
         {/* {WRepository.user && (
            <WSubTitle>
               <Stack direction={'row'} justifyContent={'center'} spacing={1} alignItems={'center'}>
                  <img width={30} height={30} src={'./globe.png'} alt="" />
                  <Box>{lang.w.추천월드목록}</Box>
                  <CIconButton
                     onClick={async () => {
                        WRepository.LoadMapList();
                        MySystem.ForceUpdate();
                     }}
                  >
                     {!MyTaskM.Empty() ? <CircularProgress size={20} /> : <Refresh />}
                  </CIconButton>
               </Stack>
            </WSubTitle>
         )} */}
         <Box pb={2}>
            <CButton
               startIcon={<Add />}
               color={'secondary'}
               onClick={async () => {
                  let category = props.category;
                  // 아래는 왜 하는 거임? 그냥 새로 만든 방이 목록에 보이게 하는 이유
                  // if (category !== 'my') {
                  //    UIAction.SetCategory('my');
                  //    await MyUtil.Wait(100);
                  //    await MyUtil.WaitUntil(() => MyTaskM.Empty());
                  // }

                  if(WRepository.IsAdmin() === false) {
                     MsgBoxM.Open(
                        lang.w.관리자권한이필요합니다
                     );                     
                     return;
                  }
                  WSystem.OpenRoomCreateWindow(undefined, undefined, category);
               }}
            >               
               {lang.w.새월드만들기/* {props.category === 'my' || props.category === 'all' || props.category === 'etc'
                  ? lang.w.새월드만들기
                  : MyStringUtil.Format(
                       lang.w.새XXX월드만들기,
                       langM.GetLocalizedText('common', props.category),
                    )} */}
            </CButton>
         </Box>
         <WMapList mapList={WRepository.mapList} />
      </Box>
   );
});
