import { Close } from '@mui/icons-material';
import { Box, IconButton, MenuItem, Modal, Select, TextField, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { RefObject, useEffect, useState } from 'react';
import { MyStringUtil } from '../../base/MyStringUtil';
import { lang, langM } from '../../config/lang';
import { CButton } from '../CButton';
import { ToastM } from '../toast/ToastM';
import { ValidatedTextField } from '../validate/ValidatedTextField';
import { IMsgBox } from './MsgBoxReducer';

const useStyles = makeStyles((theme: Theme) => ({
   modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 10002,
      width: '100%',
      height: '100%',
   },
   frame: {
      borderRadius: theme.shape.borderRadius,
      overflow: 'auto',
      maxHeight: 'calc(100% - 20px)',
      maxWidth: 'calc(100% - 20px)',
      padding: '15px',
      paddingBottom: '10px',
      backgroundColor: theme.palette.background.paper,
      boxShadow: '0px 0px 20px 1px rgba(0, 0, 0, .3)',
      animation: 'aniZoomInY 0.3s ease-in-out',
      // zIndex: 10002,
   },
   frameFullScreen: {
      // overflow: 'auto',
      animation: 'aniFadeIn 0.3s ease-in-out',
      // backgroundColor: 'red',
   },
   content: {
      // overflow: 'auto',
      // width: '100%',
      // height: '100%',
      // backgroundColor: 'red',
      // zIndex: 10002,
      textAlign: 'center',
   },
}));

/**
 * 메시지 박스
 * @author elee
 * @date 2021-04-12,
 */
let inputValues: { [key: string]: { value?: string; value0?: string; error?: boolean } } = {};
export function MsgBox(props: { data: IMsgBox; onClose: () => void }) {
   const data = props.data;

   const open: boolean = Boolean(data.isOpen);
   const [update, setUpdate] = useState<boolean>(false);
   const [showError, setShowError] = useState<boolean>(false);
   // const inputRef: RefObject<HTMLInputElement>[] = [
   //    React.useRef<HTMLInputElement>(null),
   //    React.useRef<HTMLInputElement>(null),
   //    React.useRef<HTMLInputElement>(null),
   //    React.useRef<HTMLInputElement>(null),
   // ];

   const classes = useStyles();

   useEffect(() => {
      setShowError(false);
      inputValues = {};
      if (data.inputs) {
         data.inputs.forEach((input) => {
            inputValues[input.name] = { value: input.val, value0: input.val, error: false };
         });
      }
      setUpdate(!update);
   }, [props.data]);

   /** 버튼 눌러서 닫기 */
   function handleClose(btnName?: any) {
      // btnName = btnName || 'None';
      // OK 버튼 눌리면
      if (btnName === data.btns?.[0]?.name) {
         if (Object.keys(inputValues).length > 0) {
            setShowError(true);
            // 입력값 검증
            for (const key in inputValues) {
               if (inputValues[key].error) {
                  ToastM.Open(lang.common.입력값을확인하세요, 'error');
                  return;
               }
            }

            //입력값 가져오기
            let retInputs: { [key: string]: string } = {};
            for (const key in inputValues) {
               retInputs[key] = inputValues[key].value ?? '';
            }

            //콜백
            props.onClose();
            data.cb?.({ input: retInputs, btn: btnName });
            return;
         }
      }

      //직접 닫았을 경우 콜백
      props.onClose();
      data.cb?.({ btn: btnName });
   }

   /** 배경 눌러서 닫기 */
   function handleCloseNoButton() {
      if (!data.block) {
         handleClose();
      }
   }

   return (
      <>
         {/* 블러 배경 */}
         {/* {open && <Box className={'blur'} sx={{
            width: '100vw',
            height: '100vh',
         }}>
         </Box>} */}
         {/* 모달 */}
         <Modal
            className={classes.modal}
            open={open}
            onClose={handleCloseNoButton}
            closeAfterTransition
            BackdropComponent={undefined}
            BackdropProps={{
               timeout: 300,
               style: { backgroundColor: 'rgba(0,0,0,0.2)' },
            }}
         >
            {/*<Fade in={open}>*/}
            {/*{data.children ? data.children : null}*/}
            {/*테두리*/}
            <Box className={data.fullScreen ? classes.frameFullScreen : classes.frame}>
               {/* box를 relative로 하고 버튼을 absolute로 하면 부모 기준으로 absolute가 된다. */}
               {!data.fullScreen && !data.block && (
                  <Box sx={{ position: 'relative' }}>
                     {/* 클로즈 버튼 */}
                     <IconButton
                        sx={{ position: 'absolute', right: -15, top: -15 }}
                        onClick={() => {
                           handleClose();
                        }}
                     >
                        <Close />
                     </IconButton>
                  </Box>
               )}
               <Box className={classes.content}>
                  <Box>
                     {/*컨텐트(string 타입은 특별 처리)*/}
                     {typeof data.msg === 'string' ? (
                        <Typography p={1} pt={1.5}>
                           {data.msg}
                        </Typography>
                     ) : (
                        data.msg
                     )}
                     {/*입력창*/}
                     {Object.keys(inputValues).length > 0 &&
                        data.inputs?.map((input, i) =>
                           input.type === 'list' ? (
                              <Select
                                 key={i}
                                 label={MyStringUtil.Capitalize(input.name)}
                                 value={inputValues[input.name].value}
                                 // inputRef={inputRef[i]}
                                 fullWidth
                                 color="secondary"
                                 onChange={(e) => {
                                    let value = e.target.value;
                                    if (value !== inputValues[input.name].value) {
                                       inputValues[input.name].value = value;
                                       setUpdate(!update);
                                    }
                                 }}
                              >
                                 {input.list
                                    ? input.list.map((l) => (
                                         <MenuItem value={l}>{langM.GetLocalizedText('label', l)}</MenuItem>
                                      ))
                                    : undefined}
                              </Select>
                           ) : (
                              <ValidatedTextField
                                 key={i}
                                 margin="dense"
                                 label={MyStringUtil.Capitalize(input.name)}
                                 value={inputValues[input.name].value}
                                 type={input.type}
                                 // inputRef={inputRef[i]}
                                 fullWidth
                                 color="secondary"
                                 min={input.min}
                                 max={input.max}
                                 validate
                                 showError={showError}
                                 onChange={(e) => {
                                    let value = e.target.value;
                                    if (value !== inputValues[input.name].value) {
                                       inputValues[input.name].value = value;
                                       setUpdate(!update);
                                    }
                                 }}
                                 onValidate={(error: boolean) => {
                                    inputValues[input.name].error = error;
                                 }}
                              />
                           ),
                        )}
                  </Box>
                  {/*버튼*/}
                  <Box style={{ textAlign: 'right' }}>
                     {/*<Container>*/}
                     {data.btns?.map((btn, idx) => (
                        <CButton
                           key={btn.name}
                           style={{ margin: 5 }}
                           color={idx === 0 ? 'primary' : 'secondary'}
                           onClick={() => handleClose(btn.name)}
                           children={btn.name}
                        />
                     ))}
                  </Box>
               </Box>
            </Box>
            {/*</Fade>*/}
         </Modal>
      </>
   );
}
