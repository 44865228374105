import { Avatar, AvatarProps, Stack, Typography } from '@mui/material';
import { memo } from 'react';
import { MyStringUtil } from '../../common/base/MyStringUtil';

type WAvatarProps = AvatarProps & {
   children?: any;
   photo?: string;
   shortWidth?: boolean;
   size?: number;
   onClick?: () => void;
};

export const WAvatar = memo((props: WAvatarProps) => {
   return (
      <Stack direction={'row'} flex={props.shortWidth ? undefined : 1} alignItems="center">
         {/* 아바타 */}
         <Avatar
            className={props.onClick ? 'button' : undefined}
            src={props.photo}
            onClick={props.onClick}
            sx={{
               width: props.size || 30,
               height: props.size || 30,
               mr: (props.size || 30) / 60,
               bgcolor: props.children && MyStringUtil.StringToColor(props.children),
               ...props.sx,
            }}
         />
         {/* 이름 */}
         {props.children}
      </Stack>
   );
});
