import { MyUtil } from '../../base/MyUtil';
import { ActionMsgBox, IMsgBox, IMsgBoxInput } from './MsgBoxReducer';

export type MsgBoxReturn = {
   btn: any;
   input?: { [key: string]: string }; //순서대로 정렬된 입력값
};

/**
 * 메시지 박스 매니저
 * @author elee
 * @date 2021-03-15,
 */
export class MsgBoxM {
   static list: IMsgBox[] = [];
   static lastData: IMsgBox | undefined = undefined;

   /** 박스 열기 */
   static async Open(msg: any, ...btns: string[]) {
      return this.OpenEx(
         msg,
         btns.map((name) => ({ name: name })),
         [],
      );
   }

   static async OpenEx(
      msg: any,
      btns?: { name: string; autoDelay?: number }[],
      inputs?: IMsgBoxInput[],
      block?: boolean,
   ): Promise<MsgBoxReturn> {
      if (btns)
         for (let btn of btns) {
            if (btn.autoDelay !== undefined && btn.autoDelay === 0) return { btn: btn.name };
         }

      //창 닫기
      let ret: any = undefined;

      //메시지 박스
      this.MsgBoxSync(
         msg,
         btns,
         inputs,
         (r) => {
            ret = r;
         },
         block,
      );

      //클릭 콜백 대기
      await MyUtil.WaitUntil(() => {
         return !!ret;
      });

      return ret;
   }

   /** 메시지 박스 열기 */
   private static MsgBoxSync(
      msg?: any,
      btns?: { name: string; autoDelay?: number }[],
      inputs?: IMsgBoxInput[],
      cb?: (ret: MsgBoxReturn) => void,
      block?: boolean,
   ) {
      //새 데이터 푸시
      this.list.push({
         msg: msg,
         btns: btns,
         inputs: inputs,
         cb: cb,
         isOpen: true,
         block: block,
      });

      // 데이터 적용
      this.Deque();
   }

   /** 열려 있는가? */
   static IsOpen(): boolean {
      return !!this.lastData;
   }

   /** 큐 적용 */
   private static Deque() {
      // 열려 있는 데이터가 있으면, 닫을때 다시 적용한다.
      if (!this.lastData && this.list.length > 0) {
         this.lastData = this.list.shift(); // 데이터리스트 가져와서 (순차적으로 가져오기 위해 pop 사용하면 안됨)
         if (this.lastData) ActionMsgBox.SetMsgBox(this.lastData); //보여줌
      }
   }

   /** 닫기 */
   static Close(btn?: any) {
      if (this.lastData) {
         // 콜백 호출
         if (this.lastData.cb) {
            this.lastData.cb({ btn: btn });
         }
         this.lastData = undefined;

         // 클로즈 리덕스 호출
         ActionMsgBox.SetMsgBox({ isOpen: false });

         // 다음 데이터 적용
         this.Deque();
      }
   }

   static CloseWithoutCallback(btn?: any) {
      if (this.lastData) {
         this.lastData = undefined;
         
         // 클로즈 리덕스 호출
         ActionMsgBox.SetMsgBox({ isOpen: false });

         // 다음 데이터 적용
         this.Deque();
      }
   }      
}
