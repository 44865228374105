import * as fbFirestore from 'firebase/firestore';

import { MySessionStorage } from '../../common/base/MySessionStorage';
import { MsgBoxM } from '../../common/components/msgbox/MsgBoxM';
import { ToastM } from '../../common/components/toast/ToastM';
import { config } from '../../common/config/config';
import { lang } from '../../common/config/lang';
import { MyFirebaseM } from '../../common/manager/MyFirebaseM';
import { MySystem } from '../../common/util/MySystem';

export interface WUser {
   displayName: string | null;
   email: string | null;
   emailVisibility: 'private' | 'friends' | 'public';
   photoURL: string | null;
   uid: string | null;
   desc: string | null;
   authority: 'basic' | 'pro' | 'enterprise' | 'manager' | 'admin';
   updatedAt?: any;
   like?: number;
}

export interface WUserMeta {
   like?: { [key: string]: boolean | fbFirestore.FieldValue };
}

export interface WMapInfoData {
   splash?: string;
   title?: string;
   desc?: string;
   owner?: string;
   ownerName?: string;
   ownerPhoto?: string;
   mapSize?: number;
   category?: string;
   map?: string; //기본씬
   visibility?: 'private' | 'friends' | 'public';
   editor?: string[];
   sort?: number;
   view?: number;
   replyCount?: number;
   updatedAt?: any;
   createdAt?: any;
   like?: number;
   dislike?: number;
}

export interface WDeco {
   [key: string]: { id: string; x: number; y: number; z: number; r: number; s?:number; m?: any };
}

export interface WMapData {
   mapId: string;
   deco: WDeco;
   updatedAt?: any;
}

export interface WReplyData {
   mapId?: string;
   parentId?: string;
   owner?: string;
   ownerName?: string;
   ownerPhoto?: string;
   text?: string;
   replyCount?: number;
   like?: number;
   dislike?: number;
   updatedAt?: any;
   createdAt?: any;
   deleted?: number;
}

/**
 * 전체 관리자
 */
export class WRepository {
   static mapCategory?: string;
   static mapList: { uid: string; data: any }[] = [];

   static user?: WUser;
   static guestUser = {
      displayName: '',
   };

   static IsAdmin() {
      return this.user?.authority === 'admin';
   }

   static map?: { uid: string; data: WMapInfoData };
   static mapData?: { uid: string; data: WMapData };

   static initialized = false;
   static async Init() {
      // 파이어베이스 유저면
      if (config.USE_LOGIN && MyFirebaseM.user) {
         // 로그인 정보 가져오기
         let ret = await this.FindUserInfo(MyFirebaseM.user.uid);
         this.user = ret?.data as WUser;
         if (!this.user || !this.user.authority) {
            // 로그인 되어 있지 않으면 로그인 정보 다시 저장
            let userData: WUser = {
               displayName: MyFirebaseM.user.displayName,
               email: MyFirebaseM.user.email,
               emailVisibility: 'public',
               photoURL: MyFirebaseM.user.photoURL,
               uid: MyFirebaseM.user.uid,
               desc: '',
               authority: 'basic',
            };
            await this.SaveMyInfo(userData, true, false);
         }
         // 라이크 정보 가져오기
         await this.#LoadUserMeta();
      }
      this.initialized = true;
   }

   static async LoadMap(mapId: string) {
      let ret = await this.FindMapInfo(mapId);
      this.map = ret;
      if (this.map) {
         let map = this.map.data;
         if (map) {
            if (!map.view) {
               map.view = 0;
            }
            // 조회수 증가
            if (!MySessionStorage.GetBool(mapId)) {
               ++map.view;
               MyFirebaseM.DBUpdate('maps', mapId, { view: fbFirestore.increment(1) }); //조회수 증가
               MySessionStorage.SetBool(mapId, true);
               // 조회수 데이터 갱신
               let m = this.mapList.find((v) => v.uid === mapId);
               if (m) {
                  m.data.view = map.view;
               }
            }
         }
      }
      return ret;
   }

   static async FindMapData(mapId: string) {
      return await MyFirebaseM.DBFindOne('mapData', mapId);
   }

   static async SaveMapData(mapId: string, dataSave: any) {
      await MyFirebaseM.DBUpdate('mapData', mapId, dataSave);
   }

   static async Login() {
      await MyFirebaseM.Login();
   }

   static async Logout() {
      await MyFirebaseM.Logout();
      this.user = undefined;
      this.usersMeta = undefined;
      this.mapList = [];
      await this.LoadMapList();
      MySystem.ForceUpdate();
      MsgBoxM.Open(lang.common.로그아웃되었습니다, lang.common.확인);
   }

   static async SaveMyInfo(saveData: WUser, force = false, notice = true) {
      if (force || (await this.CheckLogin())) {
         await MyFirebaseM.DBUpdate('users', MyFirebaseM.user?.uid!, saveData);
         if (this.user === undefined) (this.user as any) = {};
         Object.assign(this.user as any, saveData);
         if (notice) ToastM.Open(lang.common.저장완료);
         return true;
      }
      return false;
   }

   static async SaveUserInfo(uid: string, saveData: any, notice = true) {
      if (await this.CheckLogin()) {
         await MyFirebaseM.DBUpdate('users', uid, saveData);
         if (notice) ToastM.Open(lang.common.저장완료);
         return true;
      }
      return false;
   }

   static async FindUserInfo(uid: string) {
      return await MyFirebaseM.DBFindOne('users', uid);
   }

   /** 로그인 여부 체크 */
   static async CheckLogin() {
      if (!config.USE_LOGIN) return false;
      if (this.user) return true;
      let ret = await MsgBoxM.Open(lang.common.로그인이필요합니다, lang.common.로그인, lang.common.취소);
      if (ret.btn === lang.common.로그인) {
         MyFirebaseM.Login();
      }
      return false;
   }

   static async DeleteMap(mapId: string) {
      if (await this.CheckLogin()) {
         // await MyFirebaseM.DBDeleteDocsWhere('mapData', fbFirestore.where('mapId', '==', mapId));
         await MyFirebaseM.DBDeleteDoc(`mapData`, mapId); //맵 부터 삭제하면 owner 권한이 사라지기 때문에 맵데이터 부터 삭제해야 한다.
         await MyFirebaseM.DBDeleteDoc('maps', mapId);
         this.mapList = this.mapList.filter((v) => v.uid !== mapId);
         // this.hotMapList = this.hotMapList.filter((v) => v.uid !== mapId);
         await MyFirebaseM.StorageDeleteFolder(mapId);
         return true;
      }
      return false;
   }

   static async LoadMapList(category?: string, limit_ = 100) {
      category && (this.mapCategory = category);
      if (this.mapCategory) {
         if (this.mapCategory === 'my') {
            if (this.user) {
               this.mapList = await MyFirebaseM.DBFindWhere(
                  'maps',
                  //WhereFilterOp = '<' | '<=' | '==' | '!=' | '>=' | '>' | 'array-contains' | 'in' | 'array-contains-any' | 'not-in';
                  fbFirestore.where('owner', '==', this.user?.uid),
                  fbFirestore.orderBy('updatedAt', 'desc'),
                  fbFirestore.limit(limit_),
               );
            } else {
               this.mapList = [];
            }
         } else if (this.mapCategory === 'all') {
            if (this.IsAdmin()) {
               this.mapList = await MyFirebaseM.DBFindWhere(
                  'maps',
                  //WhereFilterOp = '<' | '<=' | '==' | '!=' | '>=' | '>' | 'array-contains' | 'in' | 'array-contains-any' | 'not-in';
                  // fbFirestore.where('visibility', '==', 'public'),
                  fbFirestore.orderBy('view', 'desc'),
                  fbFirestore.limit(limit_),
               );
            } else {
               this.mapList = await MyFirebaseM.DBFindWhere(
                  'maps',
                  //WhereFilterOp = '<' | '<=' | '==' | '!=' | '>=' | '>' | 'array-contains' | 'in' | 'array-contains-any' | 'not-in';
                  fbFirestore.where('visibility', '==', 'public'),
                  fbFirestore.orderBy('view', 'desc'),
                  fbFirestore.limit(limit_),
               );
            }
         } else {
            this.mapList = await MyFirebaseM.DBFindWhere(
               'maps',
               //WhereFilterOp = '<' | '<=' | '==' | '!=' | '>=' | '>' | 'array-contains' | 'in' | 'array-contains-any' | 'not-in';
               fbFirestore.where('visibility', '==', 'public'),
               fbFirestore.where('category', '==', this.mapCategory),
               fbFirestore.orderBy('view', 'desc'),
               // fbFirestore.orderBy('sort', 'desc'),
               // fbFirestore.orderBy('updatedAt', 'desc'),
               fbFirestore.limit(limit_),
            );
         }
         MySystem.ForceUpdate();
      }
   }

   static async FindUserMapList(userId?: string, limit_ = 100) {
      if (userId === this.user?.uid) {
         // 내꺼 보기
         return await MyFirebaseM.DBFindWhere(
            'maps',
            fbFirestore.where('owner', '==', userId),
            fbFirestore.orderBy('updatedAt', 'desc'),
            fbFirestore.limit(limit_),
         );
      } else {
         // 남꺼 보기
         return await MyFirebaseM.DBFindWhere(
            'maps',
            fbFirestore.where('visibility', '==', 'public'),
            fbFirestore.where('owner', '==', userId),
            fbFirestore.orderBy('updatedAt', 'desc'),
            fbFirestore.limit(limit_),
         );
      }
   }

   static async CreateMap(dataSave: WMapInfoData, deco: {}) {
      if (await this.CheckLogin()) {
         let [mapId] = await MyFirebaseM.DBAdd('maps', dataSave);
         await this.SaveMapData(mapId, {
            mapId: mapId,
            deco: deco,
         });
         return mapId;
      }
   }

   static async FindMapInfo(mapId: string) {
      return await MyFirebaseM.DBFindOne('maps', mapId);
   }

   static async SaveMapInfo(mapId: string, dataSave: any, notice = true) {
      if (await this.CheckLogin()) {
         await MyFirebaseM.DBUpdate('maps', mapId, dataSave);
         if (notice) ToastM.Open(lang.common.저장완료);
         return true;
      }
      return false;
   }

   //댓글 저장 ===============================================
   static async FindReplyList(boardId: string, limit_ = 100) {
      let replyList = await MyFirebaseM.DBFindWhere(
         'reply',
         fbFirestore.where('parentId', '==', boardId),
         fbFirestore.orderBy('createdAt', 'desc'),
         fbFirestore.limit(limit_),
      );
      return replyList;
   }

   static async CreateReply(dataReply: WReplyData, notice = true) {
      if (await this.CheckLogin()) {
         let [replyId] = await MyFirebaseM.DBAdd('reply', dataReply);
         if (replyId) {
            // 맵에 댓글수 증가
            if (dataReply?.mapId) {
               await MyFirebaseM.DBUpdate('maps', dataReply.mapId, {
                  replyCount: fbFirestore.increment(1),
               });
               if (this.map?.data) {
                  if (this.map.data.replyCount === undefined) this.map.data.replyCount = 0;
                  ++this.map.data.replyCount;
               }
            }
            // 댓글에 댓글수 증가 (맵이 아니면)
            if (dataReply?.parentId && dataReply.parentId !== dataReply.mapId) {
               await MyFirebaseM.DBUpdate('reply', dataReply.parentId, {
                  replyCount: fbFirestore.increment(1),
               });
            }
            if (notice) ToastM.Open(lang.common.저장완료);
            return replyId;
         }
      }
   }

   static async SaveReply(replyId: string, dataSave: WReplyData, notice = true) {
      if (await this.CheckLogin()) {
         await MyFirebaseM.DBUpdate('reply', replyId, dataSave);
         if (notice) ToastM.Open(lang.common.저장완료);
         return true;
      }
      return false;
   }

   static async DeleteReply(deleteId: string, dataReply?: WReplyData, notice = true) {
      if (await this.CheckLogin()) {
         await MyFirebaseM.DBDeleteDoc('reply', deleteId);
         // 맵에 댓글수 감소
         if (dataReply?.mapId) {
            await MyFirebaseM.DBUpdate('maps', dataReply.mapId, {
               replyCount: fbFirestore.increment(-1),
            });
            if (this.map?.data.replyCount) --this.map.data.replyCount;
         }
         // 댓글에 댓글수 감소 (맵이 아니면)
         if (dataReply?.parentId && dataReply.parentId !== dataReply.mapId) {
            await MyFirebaseM.DBUpdate('reply', dataReply.parentId, {
               replyCount: fbFirestore.increment(-1),
            });
         }
         if (notice) ToastM.Open(lang.common.삭제완료);
         return true;
      }
      return false;
   }

   static usersMeta?: WUserMeta;

   static async #LoadUserMeta() {
      if (await this.CheckLogin()) {
         let ret = await MyFirebaseM.DBFindOne('usersMeta', this.user!.uid!);
         this.usersMeta = undefined;
         if (ret) {
            this.usersMeta = ret.data;
         }
      }
   }

   static async UpdateLike(
      contentId: string,
      btnLike: boolean,
      liked: boolean,
      disliked: boolean,
      contentNode: any,
      contentType: string | 'map' | 'reply' | 'user',
   ) {
      if (await this.CheckLogin()) {
         let saveDataLikeCheck: WUserMeta = {
            like: {},
         };
         const reset = btnLike ? liked : disliked;
         //좋아요 저장
         saveDataLikeCheck.like![contentId] = reset ? fbFirestore.deleteField() : btnLike;

         if (!this.usersMeta) this.usersMeta = saveDataLikeCheck;
         if (this.usersMeta.like === undefined) this.usersMeta.like = {};

         //좋아요 카운트 반영
         let saveDataLikeCount: any = {};
         if (btnLike) {
            // 좋아요 눌렀을 때
            saveDataLikeCount.like = fbFirestore.increment(liked ? -1 : 1);
            contentNode.like = (contentNode.like || 0) + (liked ? -1 : 1);
            if (disliked) {
               saveDataLikeCount.dislike = fbFirestore.increment(-1);
               contentNode.dislike = (contentNode.dislike || 0) - 1;
            }
         } else {
            // 싫어요 눌렀을 때
            saveDataLikeCount.dislike = fbFirestore.increment(disliked ? -1 : 1);
            contentNode.dislike = (contentNode.dislike || 0) + (disliked ? -1 : 1);
            if (liked) {
               saveDataLikeCount.like = fbFirestore.increment(-1);
               contentNode.like = (contentNode.like || 0) - 1;
            }
         }

         // 좋아요 체크 미리 반영
         if (reset) {
            delete this.usersMeta.like[contentId];
         } else {
            this.usersMeta.like![contentId] = btnLike;
         }

         MySystem.ForceUpdate(); //반응성을 위해 좋아요 빨리 반영

         // 좋아요 체크 저장
         await MyFirebaseM.DBUpdate('usersMeta', this.user?.uid!, saveDataLikeCheck);

         // 좋아요 카운트 저장
         if (contentType === 'map') {
            //맵이면
            await this.SaveMapInfo(contentId, saveDataLikeCount, false);
         } else if (contentType === 'user') {
            // 유저면
            if (contentId === this.user?.uid) {
               this.user.like = contentNode.like; //내 정보 갱신
            }
            await this.SaveUserInfo(contentId, saveDataLikeCount, false);
         } else {
            //댓글이면
            await this.SaveReply(contentId, saveDataLikeCount, false);
         }

         // MySystem.ForceUpdate();
      }
   }

   /**
    * @param replyId
    * @returns true(좋아요), false(싫어요), undefined (둘다 안눌린 상태)
    */
   static IsLiked(replyId?: string | null) {
      if (replyId && this.usersMeta?.like?.[replyId] !== undefined) return !!this.usersMeta?.like?.[replyId];
   }
}
