import { APIBase } from "../../common/api/APIBase";
import { httpCode } from "../../common/api/httpCodes";
import { IAccountManager } from "../../common/api/IAccountManager";
import { MyStorage } from "../../common/base/MyStorage";
import { Singleton } from "../../common/base/Singleton";
import { lang } from "../../common/config/lang";
import { MyFirebaseM } from "../../common/manager/MyFirebaseM";
import { MyDebug } from "../../common/util/MyDebug";

class AccountUser {
   _id = '';
   login_type = '';
   login_id = '';
   name = '';
   created = '';

   image_url = '';
   email = '';
   access = '';

   IsLogin() {
      return !!this.access;
   }
}

export class WAccount implements IAccountManager {

   static get s() {
      return Singleton(WAccount, 'WAccount');
   }

   user = new AccountUser();

   async Logout(request?: boolean) {
      await MyFirebaseM.Logout();
      this.user = new AccountUser();
      MyStorage.SetObject('account', this.user);
   }

   /** 헤더 설정 */
   AddTokenHeader(header: any): void {
      if (this.user.access) header['x-auth-token'] = this.user.access;
   }

   async Login() {
      await MyDebug.Task('sign in', async () => {
         MyFirebaseM.Login();
         // const user = await MyFirebaseM.Login();
         // if (!user) return;
         // // API 로그인
         // let ret = await APIBase.Request('user/signinup', {
         //    login_type: 'google',
         //    login_id: user.uid,
         //    password: user.uid,
         //    name: user.displayName,
         //    email: user.email,
         //    image_url: user.photoURL,
         // });
         // if (ret?.status === httpCode.OK) {
         //    // 로그인 성공
         //    let data = await (ret as any).data;
         //    this.user.access = data.access_token;
         //    this._SetLoginInfo(data.user);
         // }
      });
      return this.user.IsLogin();
   }

   IsLogin() {
      return this.user.IsLogin();
   }

   GetLoginName() {
      return this.IsLogin() ? this.user.name : lang.common.손님;
   }

   GetImageUrl() {
      return this.IsLogin() ? this.user.image_url : undefined;
   }

   SetLoginInfo(user: any) {
      Object.assign(this.user, user);
      MyStorage.SetObject('account',user);
   }

   // async LoadLoginInfo() {
   //    Object.assign(this.user, MyStorage.GetObject('account', AccountUser.prototype));
   // }
}

// export const w_account = new WAccount();
// WAccount.s.LoadLoginInfo();
