import { CircularProgress, Stack } from '@mui/material';
import { memo } from 'react';

export const WPageLoading = memo(() => {
   return (
      <Stack width="100%" height="100vh" justifyContent={'center'} alignItems={'center'}>
         {/* <CircularProgress /> */}
         {/* <Typography variant={'h6'} color="white">
   {lang.common.로딩}...
</Typography> */}
      </Stack>
   );
});
