import { reduxStore } from '../../reducer/reduxStore';
import { MsgBoxReturn } from './MsgBoxM';
import { SMType } from '../smpage/SMTypes';

/**
 * 메시지박스 리듀서
 * @author elee
 * @date 2021-03-29,
 */
const SET_MSGBOX = 'SET_MSGBOX';

export namespace ActionMsgBox {
  //메인 메뉴 슬라이드 열기 닫기
  export function SetMsgBox(data: IMsgBox) {
    reduxStore.dispatch({ type: SET_MSGBOX, payload: data });
  }
}

export interface IMsgBoxInput {
  name: string;
  val?: string; //초기값
  type?: SMType;
  min?: number;
  max?: number;
  list?: string[];
}

export interface IMsgBox {
  msg?: any;
  btns?: { name: any; autoDelay?: number }[],
  inputs?: IMsgBoxInput[];
  cb?: (ret: MsgBoxReturn) => void;
  isOpen?: boolean;
  block?: boolean; //모달창 뒤에 있는 화면을 클릭하면 닫히지 않는다.
  fullScreen?: boolean; //모달창을 전체화면으로 띄운다.
  // btn?: string;
}

const initialState: IMsgBox = {
  msg: '',
  isOpen: false,
};

//#region 리듀서 ========================================
export function MsgBoxReducer(state: IMsgBox = initialState, action: { type: any; payload: any }): IMsgBox {
  switch (action.type) {
    case SET_MSGBOX:
      return {
        ...state,
        ...action.payload,
      };
  }
  return state;
}

//#endregion
