import { Report } from '@mui/icons-material';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { DocumentData } from 'firebase/firestore';
import { lang } from '../../common/config/lang';
import { MyTaskM } from '../../common/manager/MyTaskM';
import { WMapInfoData } from '../manager/WRepository';
import { WArticle } from './WArticle';

export const WMapList = (props: { mapList: { uid: string; data: any }[] }) => {
   return (
      <Box>
         {props.mapList && props.mapList.length > 0 ? (
            props.mapList.map((map, idx) => {
               return (
                  <Box key={map.uid} m={1} display="inline-block">
                     <WArticle mapId={map.uid} map={map.data as WMapInfoData} />
                  </Box>
               );
            })
         ) : (
            <>
               <Stack direction={'row'} pt={3} justifyContent={'center'} alignItems={'center'} spacing={0.5}>
                  {MyTaskM.Empty() ? (
                     <>
                        <Report color={'error'} />
                        <Typography color={'error'}>{lang.w.소유한월드가없습니다}</Typography>
                     </>
                  ) : (
                     <CircularProgress size={20} />
                  )}
               </Stack>
            </>
         )}
      </Box>
   );
};
