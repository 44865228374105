import { memo } from 'react';
import { useSelector } from 'react-redux';

import { Box, Stack } from '@mui/material';

import { myHistory } from '../../common/base/myHistory';
import { lang, langM } from '../../common/config/lang';
import { MyTaskM } from '../../common/manager/MyTaskM';
import { RootStateType } from '../../common/reducer/RootReducer';
import { UIAction } from '../../common/reducer/UIReducer';
import { MySystem } from '../../common/util/MySystem';
import { WAppBar } from '../components/WAppBar';
import { WMenuButton } from '../components/WMenuButton';
import { WSubTitle } from '../components/WTitle';
import { WRepository } from '../manager/WRepository';
// import './WMain.css';
import { WPageRoomList } from './WPageRoomList';

export const WPageMain = memo((props: {}) => {
   //상태
   useSelector((state: RootStateType) => state.ui.forceUpdate);
   let category = useSelector((state: RootStateType) => state.ui.category) || 'all';

   //메뉴 렌더링
   const WMenu = memo((props: { children: any; category: string; icon?: any }) => {
      // let theme = useTheme();
      return (
         <WMenuButton
            selected={category === props.category}
            icon={props.icon}
            onClick={() => {
               if (category === props.category) WRepository.LoadMapList(category);
               else UIAction.SetCategory(props.category);
            }}
         >
            {props.children}
         </WMenuButton>
      );
   });

   function RenderBody() {
      return (
         <>
            <Box pb={1} pt={2}>
               <WMenu category="all">{lang.common.all}</WMenu>
               <WMenu category="exhibition">{lang.common.exhibition}</WMenu>
               <WMenu category="event">{lang.common.event}</WMenu>
               <WMenu category="homepage">{lang.common.homepage}</WMenu>
               <WMenu category="etc">{lang.common.etc}</WMenu>
            </Box>
            <WPageRoomList category={category} />
            <Box p={1} />
         </>
      );
   }

   let isLoading = !MyTaskM.Empty();
   return (
      // <Stack className={'fromLeft'} direction={'column'} height={'100%'}>
      <Stack height={'100%'}>
         {/* 컨텐트 */}
         <Box
            sx={{
               flex: 1,
               overflow: 'hidden',
               overflowY: 'auto',
               textAlign: 'center',
               height: '100%',
               width: '100vw',
               background: isLoading
                  ? 'linear-gradient(to bottom, #cccccc 0px ,#fff 200px)'
                  : 'linear-gradient(to bottom, #bbddff 0px, #fff 200px)',
            }}
         >
            {/* 페이지 */}
            <Box>
               {/* 상단 메뉴 */}
               <WAppBar
                  onLogout={() => {
                     category === 'my' && UIAction.SetCategory('all');
                  }}
               />
               {/* 제목 */}
               <Box p={1} />
               <img
                  className="zoomInY"
                  src={`/logo2_1024x238_${langM.GetCurrentLanguage()}.png`}
                  alt={lang.w.제목}
                  width={300}
                  style={{
                     paddingLeft: 15,
                     paddingRight: 15,
                     maxWidth: '100%',
                  }}
               />
               <WSubTitle sx={{ fontSize: '1em', p: 0 }}>{lang.w.개요.toUpperCase()}</WSubTitle>
               {/* 내 방 목록 */}
               {RenderBody()}
               {/* 푸터 */}
               <Box m={1} fontSize={'0.8em'}>
                  <Box>
                     <img
                        className={'button'}
                        height={30}
                        src={'/logo2_256x128.png'}
                        alt="logo"
                        style={{ position: 'relative', top: 1, margin: 5 }}
                        onClick={() => {
                           myHistory.push('/about');
                           MySystem.ForceUpdate();
                        }}
                     />
                  </Box>
                  <Box>
                     {lang.common.문의} :{' '}
                     <Box display={'inline-block'}>
                        <a href="mailto:worldmakerhelp@gmail.com">worldmakerhelp@gmail.com</a>
                     </Box>
                  </Box>
                  <Box width={'auto'}>
                     <Box
                        className={'button'}
                        display={'inline-block'}
                        bgcolor={'white'}
                        onClick={() => {
                           // setShowAbout(true);
                           myHistory.push('/about');
                           MySystem.ForceUpdate();
                        }}
                     >
                        {lang.w.주소}
                     </Box>
                  </Box>
               </Box>
            </Box>
         </Box>
      </Stack>
   );
});
