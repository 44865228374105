import * as fbAnalytics from 'firebase/analytics';
import * as fbApp from 'firebase/app';
import { FirebaseOptions } from 'firebase/app';
import * as fbAuth from 'firebase/auth';
import * as fbFirestore from 'firebase/firestore';
import * as fbMessaging from 'firebase/messaging';
import * as fbStorage from 'firebase/storage';

import { MsgBoxM } from '../components/msgbox/MsgBoxM';
import { config } from '../config/config';
import { lang, langM } from '../config/lang';
import { MyDebug } from '../util/MyDebug';
import { MySystem } from '../util/MySystem';
import { MyTaskM } from './MyTaskM';

export class MyFirebaseM {
   static #app?: fbApp.FirebaseApp;
   static #db: fbFirestore.Firestore;
   static #analytics: fbAnalytics.Analytics;
   static #messaging: fbMessaging.Messaging;
   static #auth: fbAuth.Auth;
   static #storage: fbStorage.FirebaseStorage;

   static get user() {
      return this.#auth?.currentUser;
   }

   static async Init(config: FirebaseOptions) {
      this.#app = fbApp.initializeApp(config);
      this.#db = fbFirestore.getFirestore();
      this.#analytics = fbAnalytics.getAnalytics();
      this.#auth = fbAuth.getAuth();
      this.#messaging = fbMessaging.getMessaging(this.#app);
      this.#storage = fbStorage.getStorage(this.#app);

      // 리다이렉션 결과 받음
      if (!this.user) {
         await MyDebug.Task('#MyFirebaseM.Init', async () => {
            let result = await fbAuth.getRedirectResult(this.#auth);
         });
      }
   }

   static async Login() {
      if (MySystem.IsWebView()) {
         let ret = await MsgBoxM.Open(
            lang.common.현재브라우저에서는로그인할수없습니다,
            lang.common.기본웹브라우저로열기,
            lang.common.취소,
         );
         if (ret.btn === lang.common.기본웹브라우저로열기) {
            // 다른 브라우저로 열기
            MyTaskM.Start(lang.common.리다이렉트);
            MySystem.OpenUrl(config.URL_OPEN_REDIRECTION);
         }
         return;
      }
      //리다이렉션 로그인
      const provider = new fbAuth.GoogleAuthProvider();
      this.#auth.languageCode = langM.GetCurrentLanguage();
      fbAuth.signInWithRedirect(this.#auth, provider);
   }

   static async Logout() {
      await MyDebug.Task('#MyFirebaseM.Logout', async () => {
         await this.#auth.signOut();
      });
   }

   static async DBAdd(path: string, ...data: any[]) {
      let ret: string[] = [];
      await MyDebug.Task('#MyFirebaseM.DBAdd', async () => {
         let q = fbFirestore.collection(this.#db, path);
         for (let d of data) {
            let docRef = await fbFirestore.addDoc(q, {
               ...d,
               createdAt: fbFirestore.serverTimestamp(),
               updatedAt: fbFirestore.serverTimestamp(),
            });
            ret.push(docRef.id);
         }
      });
      return ret;
   }

   // static async DBSetDoc(path: string, uid: string, data: any, create = false) {
   //    await MyDebug.Task(lang.common.정보, async () => {
   //       if (create) {
   //          data.createdAt = fbFirestore.serverTimestamp();
   //       }
   //       let q = fbFirestore.doc(this.#db, path, uid);
   //       await fbFirestore.setDoc(q, {
   //          ...data,
   //          updatedAt: fbFirestore.serverTimestamp(),
   //       });
   //    });
   // }

   static async DBUpdate(path: string, uid: string, data: any) {
      await MyDebug.Task(`#MyFirebaseM.DBUpdate ${path} ${uid}`, async () => {
         let q = fbFirestore.doc(this.#db, path, uid);
         await fbFirestore.setDoc(
            q,
            {
               ...data,
               updatedAt: fbFirestore.serverTimestamp(),
            },
            { merge: true },
         );
      });
   }

   static async DBFindOne(path: string, uid: string): Promise<{ uid: string; data: any } | undefined> {
      let ret: { uid: string; data: any } | undefined = undefined;
      await MyDebug.Task(`#MyFirebaseM.DBFindOne ${path} ${uid}}`, async () => {
         let q = fbFirestore.doc(this.#db, path, uid);
         const snapShot = await fbFirestore.getDoc(q);
         if (snapShot && snapShot.exists()) ret = { uid: snapShot.id, data: snapShot.data() ?? {} };
      });
      return ret;
   }

   static async DBFindMany(path: string, ...uid: string[]) {
      let ret: { uid: string; data?: fbFirestore.DocumentData }[] = [];
      await MyDebug.Task(`#MyFirebaseM.DBFindMany ${path} ${uid}}`, async () => {
         let q = fbFirestore.collection(this.#db, path, ...uid);
         const snapShot = await fbFirestore.getDocs(q);
         ret = snapShot.docs.map((doc) => {
            return { uid: doc.id, data: doc.data() };
         });
      });
      return ret;
   }

   static async DBFindWhere(path: string, ...where: fbFirestore.QueryConstraint[]) {
      if (where === undefined) where = [];
      let ret: { uid: string; data: fbFirestore.DocumentData }[] = [];
      await MyDebug.Task(`#MyFirebaseM.DBFindWhere ${path}`, async () => {
         const q = fbFirestore.query(fbFirestore.collection(this.#db, path), ...where!);
         const snapShot = await fbFirestore.getDocs(q);
         ret = snapShot.docs.map((doc) => {
            return { uid: doc.id, data: doc.data() };
         });
      });
      return ret;
   }

   static async DBDeleteDoc(path: string, uid: string) {
      await MyDebug.Task(`#MyFirebaseM.DBDeleteDoc ${path} ${uid}`, async () => {
         let q = fbFirestore.doc(this.#db, path, uid);
         await fbFirestore.deleteDoc(q);
      });
   }

   static async DBDeleteDocsWhere(path: string, ...where: fbFirestore.QueryConstraint[]) {
      await MyDebug.Task(`#MyFirebaseM.DBDeleteDocsWhere ${path}`, async () => {
         const q = fbFirestore.query(fbFirestore.collection(this.#db, path), ...where);
         const snapShot = await fbFirestore.getDocs(q);
         snapShot.docs.forEach(async (doc) => {
            await fbFirestore.deleteDoc(doc.ref);
         });
      });
   }

   static async StorageFileList(path = '') {
      let ret: any[] = [];
      await MyDebug.Task('#MyFirebaseM.StorageFileList', async () => {
         const listRef = fbStorage.ref(this.#storage, path);
         // Find all the prefixes and items.
         await fbStorage
            .listAll(listRef)
            .then((res: any) => {
               // res.prefixes.forEach((folderRef: any) => {
               //    // All the prefixes under listRef.
               //    // You may call listAll() recursively on them.
               // });
               res.items.forEach((itemRef: any) => {
                  // All the items under listRef.
                  ret.push(itemRef);
               });
               // ret = res.items;
            })
            .catch((error: any) => {
               // Uh-oh, an error occurred!
               // MyDebug.Error(error);
               console.error(error);
            });
      });
      return ret;
   }

   static async StorageGetDownloadURL(fullPath: string) {
      return fbStorage.getDownloadURL(fbStorage.ref(this.#storage, fullPath));
   }

   static async StorageUploadFile(folderPath: string, file: any, fileName: string) {
      await MyDebug.Task(lang.common.저장, async () => {
         const storageRef = fbStorage.ref(this.#storage, `${folderPath}/${fileName}`);
         await fbStorage.uploadBytes(storageRef, file).then((snapshot: any) => {
            console.log('Uploaded a blob or file!');
         });
      });
   }

   static async StorageUploadBase64(path: string, base64str: string, mime = 'image/jpeg') {
      await MyDebug.Task('#MyFirebaseM.StorageUploadBase64', async () => {
         const storageRef = fbStorage.ref(this.#storage, path);
         await fbStorage
            .uploadString(storageRef, base64str.split(',')[1], 'base64', { contentType: mime })
            .then((snapshot: any) => {
               console.log('Uploaded a blob or file!');
            });
      });
   }

   static async StorageDeleteFile(path: string) {
      await MyDebug.Task('#MyFirebaseM.StorageDeleteFile', async () => {
         const storageRef = fbStorage.ref(this.#storage, path);
         await fbStorage.deleteObject(storageRef).then((snapshot: any) => {
            console.log('Uploaded a blob or file!');
         });
      });
   }

   static async StorageDeleteFolder(path: string) {
      await MyDebug.Task('#MyFirebaseM.StorageDeleteFolder', async () => {
         const listRef = fbStorage.ref(this.#storage, path);
         // Find all the prefixes and items.
         await fbStorage
            .listAll(listRef)
            .then((dir) => {
               dir.items.forEach((fileRef) => this.StorageDeleteFile(fileRef.fullPath));
               dir.prefixes.forEach((folderRef) => this.StorageDeleteFolder(folderRef.fullPath));
            })
            .catch((error) => console.log(error));
      });
   }
}
