import { MenuItem, Select } from '@mui/material';
import { wconfig } from '../../w/config/wconfig';
import { langM } from '../config/lang';
import { UIAction } from '../reducer/UIReducer';

/**
 * 언어 선택 드롭다운
 * @author elee
 * @date 2021-04-27,
 */
export function CLanguageSelect() {
   // const language = useSelector((state: RootStateType) => state.ui.language);
   return (
      <Select
         value={langM.GetCurrentLanguage()}
         sx={{
            height: wconfig.ui.셀렉트높이,
         }}
         onChange={(evt) => {
            langM.SetCurrentLanguage(evt.target.value);
            UIAction.ForceUpdate();
         }}
      >
         <MenuItem value="ko">Korean</MenuItem>
         <MenuItem value="en">English</MenuItem>
      </Select>
   );
}
